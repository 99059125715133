import styled, { css } from 'styled-components/macro'
import Box, { boxSystem } from '../Box'

interface SelectableProps {
  checked?: boolean
}

export interface ComponentProps {
  id?: string
  children?: any
  onChange?: any
  [x: string]: any
}

const HiddenSelectableAppearance = css`
  visibility: hidden;
`

const SelectableAppearance = css<SelectableProps>`
  width: 1.25rem;
  height: 1.25rem;
  border: 1px ${(props) => props.theme.colors.primary} solid;
  background-color: ${(props) => props.theme.colors.white};
  transition: background-color 0.1s ease-out;
  cursor: pointer;
`

const SelectableAppearanceActive = css`
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url(data:image/svg+xml;base64,CiAgPHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTMuMyA0TDYgMTEuMyAyLjcgOCIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogIDwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-size: 0.75rem 0.75rem;
  background-position: center center;
`

const ControlContainer = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.fonts.body};
  min-width: 3rem;
`

const Label = styled(Box)`
  ${boxSystem};
  margin: 0 1rem;
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  cursor: pointer;
  user-select: none;
`.withComponent('label')

Label.defaultProps = {
  fontSize: '1rem'
}

export { ControlContainer, HiddenSelectableAppearance, SelectableAppearance, SelectableAppearanceActive, Label }
