import store from 'store2'
import { CODE_TO_SYMBOL } from 'constants/currency'

const persist = store.namespace('SettingsStore') as any
export const getPersisted = (property: string) => persist.get(property) || ''

const SettingsStore = () => ({
  currency: getPersisted('currency'),
  setCurrency(currencyCode: string | null) {
    this.currency = currencyCode
    persist.set('currency', currencyCode)
  },
  toggleShowUserCurrency(currencyCode: string) {
    const sanitizedCurrencyCode = this.currency === currencyCode ? null : currencyCode
    this.setCurrency(sanitizedCurrencyCode)
    window.location.reload()
  },
  get showUserCurrency() {
    return this.currency && this.currency !== 'USD'
  },
  get currencySign() {
    return CODE_TO_SYMBOL[this.currency || 'USD']
  }
})

export default SettingsStore
