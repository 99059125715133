import React from 'react'
import styled from 'styled-components/macro'
import Box, { boxSystem } from '../Box'
import { BiLoaderAlt } from 'react-icons/bi'

const SpinnerContainer = styled(Box)`
  ${boxSystem};
`

SpinnerContainer.defaultProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '0.5rem'
}

const Spinner = ({ size = '1rem', color = 'grey' }: any) => (
  <SpinnerContainer
    color={color}
    width={size}
    height={size}
    animate={{ rotate: 360 }}
    transition={{ loop: Infinity, duration: 2 }}
  >
    <BiLoaderAlt size={size} />
  </SpinnerContainer>
)

export default Spinner
