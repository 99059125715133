import client from 'api'
import { GENERIC_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (affiliationsParams: Dictionary<any>) => {
  return client.post(GENERIC_URLS.affiliations, snakeCaseKeys(affiliationsParams))
}

export default call
