import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { getPersisted as getPersistedUserStorage, logout } from 'store/UserStore'
import { getPersisted as getPersistedSettingsStorage } from 'store/SettingsStore'
import { navigate } from '@reach/router'
import { refreshToken } from 'api/user'

const STAGING_API_URL = 'https://bamboo-backend.appunite.net/api'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL || STAGING_API_URL,
  headers: {
    'x-subject-type': 'standard'
  }
})

client.interceptors.request.use((config: any) => {
  const jwt = getPersistedUserStorage('jwt', null)
  const currencyCode = getPersistedSettingsStorage('currency')
  if (jwt) config.headers.Authorization = `Bearer ${jwt}`
  if (currencyCode) config.headers.currency = currencyCode
  return config
})

const refreshAuthLogic: any = (failedRequest: any) => {
  const persistedRefreshToken = getPersistedUserStorage('refresh-token')
  if (!persistedRefreshToken) return
  return refreshToken({ refreshTokenParams: { 'refresh-token': persistedRefreshToken } }).then(
    (tokenRefreshResponse: any) => {
      localStorage.setItem('UserStore.jwt', tokenRefreshResponse.data.jwt)
      localStorage.setItem('UserStore.refresh-token', tokenRefreshResponse.data.refresh_token)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.jwt
      navigate('/dashboard')
      return Promise.resolve()
    }
  )
}

createAuthRefreshInterceptor(client, refreshAuthLogic)

export default client
