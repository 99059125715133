import { activity, pendingOrders } from 'api/generic'
import { navigate } from '@reach/router'
import { groupBy, sortBy, take } from 'ramda'
import dayjs from 'dayjs'

const ActivityStore = () => ({
  pendingOrdersCurrencySymbol: '',
  pendingOrders: [{ type: '', symbol: '' }],
  activitiesCurrencySymbol: '',
  activities: [{ type: '', side: '' }],
  orderDetails: {},
  currentActivity: {},

  activityFilters: ['Order', 'Deposit', 'Withdrawal', 'Dividend'],
  activeActivityFilters: [] as string[],
  filteredActivityList: [] as any[],
  sortedActivities: {},
  sortedMobileActivities: {},
  paginationPerSide: 20,
  paginatedActivitiesCounter: 20,
  paginationPerMobile: 6,
  paginatedActivitiesCounterMobile: 6,
  errors: {
    pendingOrders: ''
  },
  isLoading: {
    orderDetails: false,
    activities: false
  },
  async fetchPendingOrders() {
    try {
      const { data } = await pendingOrders()
      this.pendingOrders = data.pending_orders
      this.pendingOrdersCurrencySymbol = data.currency_symbol
    } catch (error: any) {
      this.errors.pendingOrders = error.response?.data?.message || 'something went wrong'
    }
  },
  async fetchOrderDetails(id: string) {
    this.isLoading.orderDetails = true
    const {
      data: { pending_orders }
    } = await pendingOrders()
    const {
      data: { activities }
    } = await activity()

    const pendingOrder =
      pending_orders?.find((order: any) => order.id === id) || activities?.find((activity: any) => activity.id === id)

    if (!pendingOrder) return navigate('/dashboard')
    this.orderDetails = pendingOrder
    this.isLoading.orderDetails = false
  },
  async fetchActivity() {
    this.isLoading.activities = true
    const { data } = await activity()
    this.activities = data.activities
    this.activitiesCurrencySymbol = data.currency_symbol
    this.isLoading.activities = false
  },
  setCurrentActivity(currentActivity: any) {
    this.currentActivity = currentActivity
  },
  removePendingOrder(id: number) {
    const index = this.pendingOrders.findIndex((element: any) => element.id === id)
    if (!index) return
    this.pendingOrders = this.pendingOrders.splice(index, 1)
  },
  setActivityFilters(filter: string) {
    this.paginatedActivitiesCounter = this.paginationPerSide
    this.paginatedActivitiesCounterMobile = this.paginationPerMobile
    if (this.activeActivityFilters?.find((el: any) => el === filter)) {
      this.activeActivityFilters = this.activeActivityFilters.filter((el) => {
        return el !== filter
      })
    } else {
      this.activeActivityFilters.push(filter)
    }
    this.filterActivityList()
  },
  resetActivityFilters() {
    this.activeActivityFilters = []
    this.paginatedActivitiesCounter = this.paginationPerSide
    this.filterActivityList()
  },
  isActiveFilter(filter: string) {
    return this.activeActivityFilters?.includes(filter)
  },
  filterActivityList() {
    if (this.activeActivityFilters.length != 0) {
      this.filteredActivityList = []
      this.activeActivityFilters.forEach((activity: any) => {
        const activityTypeList = this.activities.filter((el) => {
          return el.type === activity || el.side === activity
        })
        this.filteredActivityList = [...this.filteredActivityList, ...activityTypeList]
      })
    } else {
      this.filteredActivityList = this.activities
    }
    this.sortActivityList()
  },
  sortActivityList() {
    const sortByDate = sortBy((activity: any) => activity.date)

    const activitiesWithDates = sortByDate(this.filteredActivityList).map((activity: any) => {
      const formattedDate = dayjs(activity.date).format('MMMM YYYY')
      return { ...activity, formattedDate: formattedDate }
    })

    const limitedActivities = activitiesWithDates.slice(0, this.paginatedActivitiesCounter)

    const sortActivities = groupBy((activity: any) => activity.formattedDate)
    this.sortedMobileActivities = sortActivities(activitiesWithDates)
    this.sortedActivities = sortActivities(limitedActivities)
  },
  loadMore() {
    this.paginatedActivitiesCounter += this.paginationPerSide
    this.filterActivityList()
  },
  get PendingOrdersShortList() {
    return take(2, this.pendingOrders)
  },
  loadMoreMobile() {
    this.paginatedActivitiesCounterMobile += this.paginationPerMobile
  },
  clearFilters() {
    this.activeActivityFilters = []
    this.activities = []
    this.sortedMobileActivities = []
    this.sortedActivities = []
  },
  StockPedingOrders(symbol: string) {
    return this.pendingOrders.filter((order: any) => order.symbol === symbol)
  }
})

export default ActivityStore
