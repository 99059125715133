import store from 'store2'
import { register, resendVerificationCode } from 'api/user'
import { reject, equals } from 'ramda'
import dayjs from 'dayjs'
import { PersonalDetails } from 'types/signup'
import { setIntercomData } from 'utils/intercom'
import countries from 'assets/data/countries.json'
import { capitalizeFirstLetter } from 'utils'

const persist = store.namespace('SignUpFormStore') as any
const getPersisted = <T = string>(property: string): T => persist.session.get('user')?.[property] || ''

const SignUpFormStore = () => ({
  user: {
    surname: getPersisted('surname'),
    referralCode: getPersisted('referralCode'),
    phonePrefix: getPersisted('phonePrefix'),
    phoneNumber: getPersisted('phoneNumber'),
    password: '',
    name: getPersisted('name'),
    gender: getPersisted('gender'),
    email: getPersisted('email'),
    dateOfBirth: getPersisted('dateOfBirth'),
    countryCode: getPersisted('countryCode'),
    citizenship: getPersisted('citizenship'),
    residenceCountryCode: 'NGA',
    countryOfResidence: getPersisted('countryOfResidence')
  },

  isLoading: {
    register: false
  },

  isFixedReturns: false,

  setPersonalDetails({
    firstName,
    lastName,
    gender,
    dateOfBirth,
    citizenship,
    phonePrefix,
    phoneNumber,
    email,
    password,
    referralCode
  }: PersonalDetails): void {
    const country = countries.find((country) => country.name === citizenship)
    const splitReferral = referralCode.split('_')
    const isHni = splitReferral[0] === 'HNI'

    this.user.name = firstName
    this.user.surname = lastName
    this.user.gender = gender
    this.user.dateOfBirth = dateOfBirth
    this.user.citizenship = country?.name || ''
    this.user.countryCode = country?.code || ''
    this.user.phonePrefix = phonePrefix
    this.user.phoneNumber = phoneNumber
    this.user.email = email
    this.user.password = password
    this.user.referralCode = isHni ? splitReferral[1] : referralCode
    persist.session('user', this.user)
  },
  async registerUser({
    errorOccured,
    handleSuccess,
    isHni
  }: {
    errorOccured: (message: string) => void
    handleSuccess: () => void
    isHni?: boolean
  }) {
    this.isLoading.register = true
    const userParams = reject(equals(''))(this.user)
    userParams.phoneNumber = this.getPhoneNumber()
    delete userParams.phonePrefix
    userParams.date_of_birth = dayjs(userParams.dateOfBirth).format('YYYY-MM-DD')
    userParams.referralCode =
      isHni && userParams.referralCode ? `HNI_${userParams.referralCode}` : userParams.referralCode
    try {
      const { data } = await register({ userParams })
      handleSuccess()
      setIntercomData(data)
    } catch (error: any) {
      errorOccured(error.response?.data.message)
    } finally {
      this.isLoading.register = false
    }
  },
  resendCode() {
    return resendVerificationCode(this.getPhoneNumber())
  },
  getSignName(): string {
    return capitalizeFirstLetter(`${this.user.name} ${this.user.surname}`)
  },
  getPhoneNumber(): string {
    return `${this.user.phonePrefix}${this.user.phoneNumber}`
  },
  setReferralCode(referralCode: string) {
    this.user.referralCode = referralCode
    persist.session('user', this.user)
  },
  setIsFixedReturns() {
    this.isFixedReturns = true
  }
})

export default SignUpFormStore
