import React from 'react'
import { Box, Text } from '../index'
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg'

const ShortMobileHeader = ({
  label,
  leftAction,
  rightAction,
  isSticky = false,
  mobileOnly = false,
  hasCenteredLabel = false,
  labelColor = 'black',
  leftIconFill = '#0ccd9d',
  rightIconFill = '#0ccd9d',
  ...rest
}: any) => {
  return (
    <Box
      display={mobileOnly ? ['flex', 'none'] : 'flex'}
      paddingTop="max(env(safe-area-inset-top, 2rem), 0.75rem)"
      paddingBottom="0.75rem"
      justifyContent="space-between"
      alignItems="center"
      zIndex={2}
      position={isSticky ? 'sticky' : 'static'}
      margin={[0, '0.5rem 0']}
      top={0}
      {...rest}
    >
      <Box
        color="primary"
        display="flex"
        onClick={leftAction?.onClick}
        style={{ cursor: 'pointer' }}
        alignItems="center"
        marginLeft="0.5rem"
        marginTop="0.5rem"
      >
        {leftAction?.Icon ? (
          <Box color="transparent" as={leftAction.Icon} width="1.5rem" height="1.5rem" fill={leftIconFill} />
        ) : (
          <ArrowBackIcon />
        )}
        <Text display={['none', 'block']} fontWeight="semiBold" margin={0} marginLeft="1rem">
          {leftAction?.label}
        </Text>
      </Box>
      <Box color={labelColor} fontSize="14px" fontWeight="semiBold" textAlign={hasCenteredLabel ? 'center' : 'left'}>
        {label}
      </Box>
      <Box color="transparent" minWidth="1.5rem" onClick={rightAction?.onClick} style={{ cursor: 'pointer' }}>
        {rightAction?.Icon ? <rightAction.Icon size="1.5rem" fill={rightIconFill} /> : <span />}
      </Box>
    </Box>
  )
}

export default ShortMobileHeader
