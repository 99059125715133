import { fixedReturns, plans } from 'api/fixedReturns'
import { IFixedReturnsPlans } from 'api/fixedReturns/plans'
import { Invest } from 'types/fixedReturns'

const FixedReturnStore = () => ({
  fixedReturnsData: [],
  plans: {} as IFixedReturnsPlans,
  amount: 0,

  isLoading: {
    fixedReturns: false,
    tenor: false,
    plans: false
  },

  hasError: {
    fixedReturns: false,
    tenor: false,
    plans: false
  },

  async fixedReturns(payload: Invest) {
    this.isLoading.fixedReturns = true
    try {
      const { data } = await fixedReturns(payload)
      this.fixedReturnsData = data
      sessionStorage.setItem('fr_amount', JSON.stringify(payload.amount))
      this.amount = payload.amount
    } catch (error) {
      this.hasError.fixedReturns = true
      throw error
    } finally {
      this.isLoading.fixedReturns = false
    }
  },

  async fetchPlans() {
    this.isLoading.plans = true
    try {
      const { data } = await plans()
      data.predefined_plans.sort((a, b) => a.timeframe_in_days - b.timeframe_in_days)
      this.plans = data
    } catch (error) {
      this.hasError.plans = true
    } finally {
      this.isLoading.plans = false
    }
  }
})

export default FixedReturnStore
