import {
  graph,
  whyIsItMoving,
  analystRating,
  news,
  markFavorite,
  unmarkFavorite,
  basicInfo,
  financialStats,
  ownership,
  about
} from 'api/stocks'
import { openDate } from 'api/market'
import { splitEvery, take } from 'ramda'

const StockViewStore = () => ({
  stockBasicInfo: {},
  stockFinancialStats: {},
  stockAbout: {},
  pendingOrders: [],
  stockOwnership: {} as any,
  stockGraph: [],
  dateRange: '1w',
  usdToUserCurrency: 1,
  whyIsItMoving: '',
  analystRatings: {},
  relatedNews: [],
  marketOpenDate: {},
  paginationPerSide: 8,
  paginatedNewsCounter: 8,
  isLoading: {
    basicInfo: false,
    financialStats: false,
    stockGraph: false,
    whyIsItMoving: false,
    analystRatings: false,
    relatedNews: false,
    markStockFavorite: false,
    stockOwnership: true,
    stockAbout: true
  },
  hasError: {
    stockBasicInfo: false,
    stockGraph: false,
    whyIsItMoving: false,
    analystRatings: false,
    relatedNews: false
  },
  async fetchStockBasicInfo(symbol: string) {
    this.isLoading.basicInfo = true
    this.hasError.stockBasicInfo = false
    try {
      const { data } = await basicInfo(symbol)
      this.stockBasicInfo = data
    } catch (error) {
      this.hasError.stockBasicInfo = true
      throw error
    } finally {
      this.isLoading.basicInfo = false
    }
  },
  async fetchStockOwnership(symbol: string) {
    this.isLoading.stockOwnership = true
    const { data } = await ownership(symbol)
    this.stockOwnership = data
    this.isLoading.stockOwnership = false
  },
  async fetchFinancialStats(symbol: string) {
    this.isLoading.financialStats = true
    const { data } = await financialStats(symbol)
    this.stockFinancialStats = data
    this.isLoading.financialStats = false
  },
  async fetchStockGraph(symbol: string, dateRange: string) {
    this.dateRange = dateRange
    this.stockGraph = []
    this.isLoading.stockGraph = true
    try {
      const {
        data: { data, usd_to_user_currency }
      } = await graph(symbol, dateRange)
      this.stockGraph = this.getSanitizedGraphData(data)
      this.usdToUserCurrency = usd_to_user_currency
      this.hasError.stockGraph = false
    } catch {
      this.hasError.stockGraph = true
    } finally {
      this.isLoading.stockGraph = false
    }
  },
  async fetchWhyIsItMoving(symbol: string) {
    this.whyIsItMoving = ''
    this.isLoading.whyIsItMoving = true
    try {
      const {
        data: { title }
      } = await whyIsItMoving(symbol)
      this.whyIsItMoving = title
    } catch {
      this.hasError.whyIsItMoving = true
    } finally {
      this.isLoading.whyIsItMoving = false
    }
  },
  async fetchAnalystRatings(symbol: string) {
    this.analystRatings = {}
    this.isLoading.analystRatings = true
    try {
      const { data } = await analystRating(symbol)
      this.analystRatings = data
    } catch {
      this.hasError.analystRatings = true
    } finally {
      this.isLoading.analystRatings = false
    }
  },
  async fetchStockAbout(symbol: string) {
    this.isLoading.stockAbout = true
    const { data } = await about(symbol)
    this.stockAbout = data
    this.isLoading.stockAbout = false
  },
  async fetchStockRelatedNews(symbol: string) {
    this.relatedNews = []
    this.isLoading.relatedNews = true
    try {
      const {
        data: { stock_news }
      } = await news(symbol)
      this.relatedNews = stock_news
    } catch {
      this.hasError.relatedNews = true
    } finally {
      this.isLoading.relatedNews = false
    }
  },
  async fetchOpenDate() {
    const { data } = await openDate()
    this.marketOpenDate = data
  },
  async toggleFavorite({ symbol, isFavorite }: { symbol: string; isFavorite: boolean }) {
    this.isLoading.markStockFavorite = true
    if (isFavorite) {
      await unmarkFavorite(symbol)
    } else {
      await markFavorite(symbol)
    }
    await this.fetchStockBasicInfo(symbol)
    this.isLoading.markStockFavorite = false
  },
  getSanitizedGraphData(data: any) {
    return data.map((entry: any) => ({
      date: entry.date,
      minute: entry.minute,
      price: parseFloat(entry.price)
    }))
  },
  hasShares() {
    return !!this.stockOwnership?.ownership?.shares
  },
  get featuredInSplittedList() {
    return splitEvery(6, (this.stockAbout as any)?.categories || [])
  },
  loadMoreStockRelatedNews() {
    this.paginatedNewsCounter += this.paginationPerSide
  },
  get stockRelatedNewsShortList() {
    return take(3, this.relatedNews)
  },
  get limitedStockRelatedNews() {
    return take(this.paginatedNewsCounter, this.relatedNews)
  }
})

export default StockViewStore
