import styled from 'styled-components/macro'
import Box, { boxSystem } from '../Box'

const SafeAreaBox = styled(Box)`
  ${boxSystem};
`

SafeAreaBox.defaultProps = {
  paddingTop: 'env(safe-area-inset-top, 1rem)',
  paddingRight: 'env(safe-area-inset-right, 1rem)',
  paddingBottom: 'env(safe-area-inset-bottom, 1rem)',
  paddingLeft: 'env(safe-area-inset-left, 1rem)'
}

export default SafeAreaBox
