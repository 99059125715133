import styled from 'styled-components/macro'
import Box, { boxSystem } from '../Box'

const Label = styled(Box).attrs({
  className: 'b-label'
})`
  ${boxSystem};
  transition: color 0.3s ease-out;
`.withComponent('label')

Label.defaultProps = {
  fontSize: '0.75rem',
  marginBottom: '0.25rem',
  color: 'grey'
}

export default Label
