import client from '../'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'

interface BankVerificationNumber {
  bvnParams: Dictionary<string>
}

const call = ({ bvnParams }: BankVerificationNumber) => {
  return client.post('verify_bvn', snakeCaseKeys(bvnParams))
}

export default call
