import client from 'api/index'
import { ORDERS_URLS } from 'constants/api'
import snakeCaseKeys from 'snakecase-keys'
import { CalculationParams } from 'types/orders'

const call = (calculationParams: CalculationParams) => {
  return client.post(ORDERS_URLS.calculate, snakeCaseKeys(calculationParams))
}

export default call
