import { useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { useStore } from 'store'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

const AcceptGiftView = (props: RouteComponentProps) => {
  const { t } = useTranslation()
  const { GenericStore } = useStore()
  const { addToast } = useToasts()

  useEffect(() => {
    const acceptGift = async () => {
      const params = new URLSearchParams(props.location?.search)
      const identifier = params.get('identifier')
      if (!identifier) return
      try {
        await GenericStore.acceptGift(identifier)
        addToast(t('Gifting:Gift Accepted'), { appearance: 'success' })
      } catch {
        addToast(t('Gifting:Gift Accepting Error'), { appearance: 'error', autoDismiss: false })
      }
      await navigate('/login')
    }
    acceptGift()
  }, [GenericStore, addToast, props.location, t])

  return null
}

export default AcceptGiftView
