import React, { createContext, useContext } from 'react'
import { Nullable, Dictionary } from 'types'
import { useLocalStore } from 'mobx-react-lite'
import SignUpFormStore from './SignUpStore'
import WizardLayoutStore from './WizardLayoutStore'
import UserStore from './UserStore'
import DashboardStore from './DashboardStore'
import PortfolioStore from './PortfolioStore'
import StockSearchStore from './StockSearchStore'
import StockViewStore from './StockViewStore'
import ProfileStore from './ProfileStore'
import LearnStore from './LearnStore'
import OrderStore from './OrderStore'
import DepositStore from './DepositStore'
import GenericStore from './GenericStore'
import TransferStore from './TransferStore'
import GiftingStore from './GiftingStore'
import DocumentsStore from './DocumentsStore'
import WithdrawalStore from './WithdrawalStore'
import InvitationStore from './InvitationStore'
import SettingsStore from './SettingsStore'
import ThemesStore from './ThemesStore'
import ResetPasswordStore from './ResetPasswordStore'
import StockAlertsStore from './StockAlertsStore'
import NotificationsStore from './NotificationsStore'
import TopMoversStore from './TopMoversStore'
import ActivityStore from './ActivityStore'
import FixedReturnStore from './FixedReturnsStore'

const storesIndex = [
  SignUpFormStore,
  WizardLayoutStore,
  UserStore,
  DashboardStore,
  PortfolioStore,
  StockSearchStore,
  StockViewStore,
  ProfileStore,
  LearnStore,
  OrderStore,
  DepositStore,
  GenericStore,
  TransferStore,
  GiftingStore,
  DocumentsStore,
  WithdrawalStore,
  InvitationStore,
  SettingsStore,
  ThemesStore,
  ResetPasswordStore,
  StockAlertsStore,
  NotificationsStore,
  TopMoversStore,
  ActivityStore,
  FixedReturnStore
]

interface BambooStoreContext {
  [storeName: string]: Dictionary<Nullable<any>>
}

const storeDefaultValues: any = {}
storesIndex.forEach((store) => {
  storeDefaultValues[store.name] = {}
})

const StoreContext = createContext<BambooStoreContext>(storeDefaultValues)

interface StoreProviderProps {
  children: React.ReactNode
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const stores = {
    SignUpStore: useLocalStore(SignUpFormStore),
    WizardLayoutStore: useLocalStore(WizardLayoutStore),
    UserStore: useLocalStore(UserStore),
    DashboardStore: useLocalStore(DashboardStore),
    PortfolioStore: useLocalStore(PortfolioStore),
    StockSearchStore: useLocalStore(StockSearchStore),
    StockViewStore: useLocalStore(StockViewStore),
    ProfileStore: useLocalStore(ProfileStore),
    LearnStore: useLocalStore(LearnStore),
    OrderStore: useLocalStore(OrderStore),
    DepositStore: useLocalStore(DepositStore),
    GenericStore: useLocalStore(GenericStore),
    TransferStore: useLocalStore(TransferStore),
    GiftingStore: useLocalStore(GiftingStore),
    DocumentsStore: useLocalStore(DocumentsStore),
    WithdrawalStore: useLocalStore(WithdrawalStore),
    InvitationStore: useLocalStore(InvitationStore),
    SettingsStore: useLocalStore(SettingsStore),
    ThemesStore: useLocalStore(ThemesStore),
    ResetPasswordStore: useLocalStore(ResetPasswordStore),
    StockAlertsStore: useLocalStore(StockAlertsStore),
    NotificationsStore: useLocalStore(NotificationsStore),
    TopMoversStore: useLocalStore(TopMoversStore),
    ActivityStore: useLocalStore(ActivityStore),
    FixedReturnStore: useLocalStore(FixedReturnStore)
  }
  return <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
}

export const useStore = () => useContext(StoreContext)
export { useObserver } from 'mobx-react-lite'

export default StoreProvider
