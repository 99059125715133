import React from 'react'
import Box from '../Box'
import { useObserver } from 'store'

interface ProgressBarProps {
  progress: {
    steps: number
    currentStep: number
  }
}

const ProgressBar = ({ progress }: ProgressBarProps) => {
  const isStepActive = (stepNumber: number) => stepNumber === progress.currentStep
  const isStepBehind = (stepNumber: number) => stepNumber < progress.currentStep
  return useObserver(() => (
    <Box display="flex" marginBottom="1.5rem" justifyContent="center" alignItems="center">
      {[...Array(progress?.steps)].map((_, stepNumber) => (
        <Box
          key="1"
          backgroundColor={isStepActive(stepNumber) || isStepBehind(stepNumber) ? 'primary' : 'lightGrey'}
          width={isStepActive(stepNumber) ? '1rem' : '0.25rem'}
          height="0.25rem"
          margin="0.25rem"
          borderRadius="0.25rem"
        />
      ))}
    </Box>
  ))
}

export default ProgressBar
