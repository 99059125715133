import React from 'react'
import theme from 'theme'
import { IconProps } from 'types'

const InfoIcon = ({ fill = theme.colors.darkBlue, width = 24, height = 24 }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 0a12 12 0 110 24 12 12 0 010-24zm1.35 9.45h-3.6v2.4h1.2v3.6h-1.2v2.4h4.8v-2.4h-1.2v-6zm-1.2-4.8a1.8 1.8 0 100 3.6 1.8 1.8 0 000-3.6z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
)

export default InfoIcon
