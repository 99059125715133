import React from 'react'
import Box, { boxSystem, BoxProps } from '../Box'
import styled from 'styled-components/macro'
import InputAppearance, { Icon } from '../mixins/InputAppearance'

interface SelectProps extends BoxProps {
  hasError?: boolean
  hasIcon?: boolean
}

const Select = styled(Box)<SelectProps>`
  ${boxSystem};
  ${InputAppearance};
  background-repeat: no-repeat;
  background-position: center right 0.75rem;
  min-height: 41px;
  ${(props) =>
    props.hasIcon &&
    `
    padding-left: 3rem;
  `}
  &:disabled {
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer;
  }
`.withComponent('select')

Select.defaultProps = {
  backgroundColor: 'transparent'
}

interface ComponentProps {
  IconComponent?: any
  [x: string]: any
}

export default React.forwardRef((props: ComponentProps, ref?: any) => {
  const {
    IconComponent,
    placeholder,
    children,
    hasLabel = true,
    onClick,
    pointerEventsDisabled = false,
    ...rest
  } = props
  return (
    <Box position="relative">
      <Select
        required
        hasIcon={!!IconComponent}
        ref={ref}
        backgroundImage="url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+QTc4MjNDRjctQjUxMS00OEE0LTg4REMtQjRDMkM1MUVCOERGQDF4PC90aXRsZT4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQmFtYm9vLVdlYi9BdG9tcy9JY29ucy9UaWVyLTMvQXJyb3cvRG93bi9DZW50ZXJlZCIgZmlsbD0iIzBGREJBOCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIHBvaW50cz0iMTcuMTE4OCA3LjY4IDE4Ljg4NjU2NyA5LjQ0Nzc2Njk1IDEyLjAwMzI4MzUgMTYuMzMxMDUwNCA1LjEyIDkuNDQ3NzY2OTUgNi44ODc3NjY5NSA3LjY4IDEyLjAwMzY4MzUgMTIuNzk0ODgzNSI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')"
        style={{ pointerEvents: pointerEventsDisabled ? 'none' : 'auto' }}
        {...rest}
      >
        <option value="" disabled selected>
          {placeholder}
        </option>
        {children}
      </Select>
      {!!IconComponent && (
        <Icon>
          <IconComponent />
        </Icon>
      )}
    </Box>
  )
})
