import client from 'api/index'
import { ALERTS_URLS } from 'constants/api'
import snakeCaseKeys from 'snakecase-keys'
import { StockAlertsParams } from 'types/alerts'

const call = (stockAlertsParams: StockAlertsParams) => {
  return client.post(ALERTS_URLS.create, snakeCaseKeys(stockAlertsParams))
}

export default call
