import client from 'api/index'
import { NEXT_OF_KIN_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (nextOfKinParams: Dictionary<string>) => {
  return client.post(NEXT_OF_KIN_URLS.nextOfKin, snakeCaseKeys(nextOfKinParams))
}

export default call
