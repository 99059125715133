import React, { useEffect } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import { Box, Button, Card, Heading, Text } from 'components'

const DepositRedirect = (props: RouteComponentProps) => {
  // const navigate = useNavigate()

  // const locationReplace = (url: string) => {
  //   console.log('wchodzi3')
  //   return window.location.replace(url)
  // }

  // const redirectToMobile = (path: string) => {
  //   console.log('wchodzi2')
  //   return locationReplace(`bamboo://${path}`)
  // }

  // const isMobile = () => !!navigator.userAgent.match(/Android|iPhone|iPad|iPod/i)

  // useEffect(() => {
  //   if (isMobile()) {
  //     console.log('wchodzi1')
  //     redirectToMobile('account/deposit')
  //     setTimeout(() => {
  //       navigate('/dashboard?action=deposit')
  //     }, 250)
  //   } else {
  //     navigate('/dashboard?action=deposit')
  //   }
  // }, [])

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" textAlign="center">
      <Card minWidth="28rem">
        <Heading>Redirection</Heading>
        <Text marginTop="2rem">You are about to get redirected to Account Deposit</Text>
        <Link to="/dashboard">
          <Button variant="primary" marginTop="2rem" width="100%">
            Proceed to Account Deposit
          </Button>
        </Link>
      </Card>
    </Box>
  )
}

export default DepositRedirect
