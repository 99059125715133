import React from 'react'
import { Box } from '../'
import InfoIcon from '../icons/Info'
import { BoxProps } from '../Box'

interface InfoProps extends BoxProps {
  children: React.ReactNode
  color?: any
}

const Info = ({ children, ...rest }: InfoProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      backgroundColor="lightBlue"
      width="100%"
      fontSize="0.75rem"
      borderRadius="0.25rem"
      padding="1rem 1.5rem"
      data-testid="dashboard__whyIsThePriceMoving"
      {...rest}
    >
      <Box flex={1}>
        <InfoIcon width={16} height={16} />
      </Box>
      <Box flex={15} color="darkBlue" padding="0.5rem">
        {children}
      </Box>
    </Box>
  )
}

export default Info
