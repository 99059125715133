import { investmentProfile, affiliations, verifyIdentityIdentifier } from 'api/generic'
import { Dictionary, Nullable } from 'types'

const ProfileStore = () => ({
  investmentProfileStep: 0,
  affiliations: {
    broker: null as Nullable<boolean>,
    director: null as Nullable<boolean>
  },
  isLoading: {
    investmentProfileSubmission: false,
    affiliationsSubmission: false,
    identityIdentifier: false
  },
  setInvestmentProfileStep(step: number) {
    this.investmentProfileStep = step
  },
  async setInvestmentProfile(data: Dictionary<any>) {
    this.isLoading.investmentProfileSubmission = true
    await investmentProfile(this.getSanitizedInvestmentProfileData(data))
    this.isLoading.investmentProfileSubmission = false
  },
  async setAffiliations({ directorOf }: { directorOf: string }) {
    this.isLoading.affiliationsSubmission = true
    await affiliations({ directorOf: directorOf || null, broker: this.affiliations.broker })
    this.isLoading.affiliationsSubmission = false
  },
  async setIdentityIdentifier(data: Dictionary<string>) {
    this.isLoading.identityIdentifier = true
    await verifyIdentityIdentifier(data)
    this.isLoading.identityIdentifier = false
  },
  getSanitizedInvestmentProfileData(data: Dictionary<string>) {
    return {
      ...data,
      dependents: parseInt(data.dependents),
      liquid: parseInt(data.liquid),
      netWorth: parseInt(data.netWorth),
      yearlyIncome: parseInt(data.yearlyIncome)
    }
  },
  setAffiliationsData(data: { broker: boolean; director: boolean }) {
    this.affiliations = data
  }
})

export default ProfileStore
