import React from 'react'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import { ThemeProvider } from 'styled-components/macro'
import theme from 'theme'
import { ToastProvider } from 'react-toast-notifications'
import { StoreProvider } from 'store'
import Routing from 'Routing'
import 'utils/I18n'
import { Helmet } from 'react-helmet'
import 'assets/styles/range.css'
import 'assets/styles/tippy.css'
import MixPanelProvider from 'hooks/MixPanel'

const App = () => {
  return (
    <>
      <Helmet titleTemplate="%s - Bamboo" defaultTitle="Bamboo" />
      <ThemeProvider theme={theme}>
        <ToastProvider autoDismiss={true}>
          <MixPanelProvider>
            <StoreProvider>
              <Routing />
            </StoreProvider>
          </MixPanelProvider>
        </ToastProvider>
      </ThemeProvider>
    </>
  )
}

export default App
