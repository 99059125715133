import React, { FC, createContext, useContext } from 'react'
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser'

const MixPanelToken = process.env.REACT_APP_MIX_PANEL_TOKEN || '7da5678ae26c5c84b20c7f4712cc884e'

mixpanel.init(MixPanelToken, { debug: process.env.NODE_ENV === 'development', ignore_dnt: true })

const MixPanelContext = createContext<OverridedMixpanel>({} as any)

const MixPanelProvider: FC = ({ children }) => {
  return <MixPanelContext.Provider value={mixpanel}>{children}</MixPanelContext.Provider>
}

export const useMixPanel = () => useContext(MixPanelContext)
export default MixPanelProvider
