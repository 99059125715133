import React from 'react'
import { Card, Box } from 'components'
import { formatCurrency } from 'utils/format'
import { SYMBOL_TO_CODE } from 'constants/currency'
import { Link } from '@reach/router'
import { truncate, isTruncated } from 'utils/text'
import theme from 'theme'
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg'

interface AssetCardProps {
  name: string
  symbol: string
  price: number
  image: string
  currencySymbol: string
  favourite: boolean
}

const AssetCard = ({ name, symbol, price, image, currencySymbol, favourite }: AssetCardProps) => {
  const truncatedName = truncate(name, 24)

  return (
    <Link to={`/dashboard/stock/${symbol}`}>
      <Card
        maxWidth="20rem"
        backgroundColor="white"
        display="flex"
        alignItems="center"
        minHeight="5.75rem"
        hover={{
          boxShadow: '0 0.25rem 10px 0 rgba(0, 0, 0, 0.2)'
        }}
      >
        <Box
          display="flex"
          minWidth="2.25rem"
          maxWidth="2.25rem"
          justifyContent="space-between"
          marginRight="1.25rem"
          padding={0}
          position="relative"
        >
          <Box as="img" src={image} height="2.5rem" minWidth="2.5rem" maxWidth="2.5rem" />
          {favourite && (
            <Box position="absolute" right="-0.75rem" bottom="-1.25rem">
              <HeartIcon width="0.875rem" />
            </Box>
          )}
        </Box>
        <Box>
          <Box margin={0}>{formatCurrency({ value: price, currency: SYMBOL_TO_CODE[currencySymbol] })}</Box>
          <Box margin={0} color={theme.colors.darkGrey} fontSize="0.875rem">
            {isTruncated(name, truncatedName) ? `${truncatedName}...` : name}
          </Box>
        </Box>
      </Card>
    </Link>
  )
}

export default AssetCard
