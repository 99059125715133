import { Dictionary } from 'types'

export const CODE_TO_SYMBOL = {
  USD: '$',
  NGN: '₦',
  GHS: 'GH₵'
} as Dictionary<string>

export const SYMBOL_TO_CODE = Object.fromEntries(
  Object.entries(CODE_TO_SYMBOL).map(([key, value]): any => [value, key])
) as Dictionary<string>
