import { create, getAll, destroy } from 'api/alerts'
import { StockAlertsParams } from 'types/alerts'

const StockAlertsStore = () => ({
  stockAlerts: [],
  async fetchAlerts(stockSymbol: string) {
    const {
      data: { stock_alerts }
    } = await getAll(stockSymbol)
    this.stockAlerts = stock_alerts
  },
  createAlert(stockAlertsParams: StockAlertsParams) {
    return create(stockAlertsParams)
  },
  deleteAlert(id: number) {
    return destroy(id)
  },
  get week52PriceAlerts() {
    return this.stockAlerts.filter((alert: any) => alert?.alert_type === 'week_52')
  },
  get priceChangeAlerts() {
    return this.stockAlerts.filter((alert: any) => alert?.alert_type === 'price')
  },
  get percentChangeAlerts() {
    return this.stockAlerts.filter((alert: any) => alert?.alert_type === 'percent')
  }
})

export default StockAlertsStore
