import React, { useState } from 'react'
import { Box, ContentLoaderRect } from 'components'
import ContentLoader from 'react-content-loader'
import { FiChevronRight, FiInfo } from 'react-icons/fi'
import theme from 'theme'
import styled, { css } from 'styled-components/macro'

const CellBox = styled(Box)`
  border-bottom: none;
  &:first-of-type {
    border-top-left-radius: ${theme.radii.default};
    border-top-right-radius: ${theme.radii.default};
  }

  .cell:last-of-type {
    border-bottom: ${theme.borders.default};
    border-bottom-left-radius: ${theme.radii.default};
    border-bottom-right-radius: ${theme.radii.default};
  }

}
${(props) =>
  props.borderBottom &&
  css`
    border-bottom: ${theme.borders.default};
  `}
`

const Cell = ({
  label,
  basicLabel,
  height,
  children,
  tooltip,
  isLoading = false,
  valueAlign = 'right',
  flexDirection = 'row',
  testId,
  onArrowClick,
  arrowTestId,
  borderBottom,
  ...rest
}: any) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false)
  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible)
  }
  return (
    <>
      <CellBox
        display="flex"
        justifyContent={valueAlign === 'left' ? 'start' : 'space-between'}
        alignItems={flexDirection === 'column' ? 'start' : 'center'}
        flexDirection={flexDirection}
        padding="0.625rem 0.75rem"
        height={height}
        border="default"
        data-testid={testId}
        borderBottom={borderBottom}
        {...rest}
      >
        <Box
          flex={valueAlign === 'left' ? 'none' : 1}
          color={basicLabel ? theme.colors.text : theme.colors.grey}
          fontSize={basicLabel ? '1rem' : '0.75rem'}
          style={{
            textTransform: basicLabel ? 'none' : 'uppercase'
          }}
          display="flex"
          alignItems="center"
        >
          {label}
          {tooltip && (
            <Box marginLeft="0.5rem" padding="0.25rem" onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
              <FiInfo size="1rem" />
            </Box>
          )}
        </Box>
        {isLoading ? (
          <ContentLoader width="8rem" height="1.5rem">
            <ContentLoaderRect />
          </ContentLoader>
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="right"
              marginRight={onArrowClick ? '1rem' : 0}
              paddingLeft={valueAlign === 'left' ? '0.25rem' : 0}
              fontSize={valueAlign === 'left' ? '0.75rem' : '1rem'}
              data-testid={`${testId}__value`}
            >
              {children || '-'}
            </Box>
            {onArrowClick && (
              <Box onClick={onArrowClick} color="primary" style={{ cursor: 'pointer' }} data-testid={arrowTestId}>
                <FiChevronRight size="1.25rem" />
              </Box>
            )}
          </>
        )}
      </CellBox>
      {isTooltipVisible && (
        <Box as="p" padding="0.25rem 0" fontSize="0.75rem">
          {tooltip}
        </Box>
      )}
    </>
  )
}

export default Cell
