import React from 'react'
import theme from 'theme'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'
import rgba from 'polished/lib/color/rgba'
import Box from '../Box'
import { fadeIn, slideDown } from '../mixins/animations'
import { ReactComponent as XIcon } from 'assets/icons/x.svg'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import { useHotkeys } from 'react-hotkeys-hook'

const ModalOverlay = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  max-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${(props) => rgba(props.theme.colors.navy, 0.85)};
  cursor: pointer;
  z-index: 2;
  animation: ${fadeIn} 0.15s ease-out;
`

ModalOverlay.defaultProps = {
  alignItems: ['flex-start', 'center']
}

const ModalContainer = styled(Box)`
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 2;
  box-shadow: 0 0.5rem 1rem ${(props) => rgba(props.theme.colors.darkBackground, 0.075)};
  cursor: default;
  animation: ${slideDown} 0.15s ease-out;
`

ModalContainer.defaultProps = {
  borderRadius: theme.radii.default,
  minHeight: '10rem',
  padding: ['1rem', '2rem'],
  margin: ['auto 0', '-3rem 0 0 0']
}

const Footer = styled(Box)`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
`

export const stopPropagation = (e: any) => {
  e.stopPropagation()
}

interface ModalProps {
  isVisible: boolean
  hasX?: boolean
  onHide: any
  children?: any
  as?: any
  onSubmit?: any
  noValidate?: boolean
  overlayPadding?: any
  LeftAction?: any
  testId?: string
  maxWidth?: string
}

const Modal = ({
  as,
  isVisible,
  hasX,
  onHide,
  children,
  onSubmit,
  noValidate,
  LeftAction,
  testId,
  overlayPadding,
  maxWidth,
  ...rest
}: ModalProps & any) => {
  useHotkeys('escape', () => isVisible && onHide())

  return isVisible
    ? ReactDOM.createPortal(
        <ModalOverlay onClick={onHide} padding={overlayPadding}>
          <ScrollLock isActive={isVisible} />
          <TouchScrollable>
            <ModalContainer
              as={as}
              onSubmit={onSubmit}
              onClick={stopPropagation}
              data-testid={testId}
              noValidate={noValidate}
              maxWidth={maxWidth ? maxWidth : '40rem'}
              {...rest}
            >
              {LeftAction && (
                <Box position="absolute" top={['1rem', '2rem']} left={['1rem', '2rem']} style={{ cursor: 'pointer' }}>
                  <LeftAction />
                </Box>
              )}
              {hasX && (
                <Box
                  position="absolute"
                  top={['1rem', '1rem']}
                  right={['1rem', '2rem']}
                  onClick={onHide}
                  style={{ cursor: 'pointer' }}
                >
                  <Box as={XIcon} width="1.5rem" height="1.5rem" />
                </Box>
              )}
              <Box>{children}</Box>
            </ModalContainer>
          </TouchScrollable>
        </ModalOverlay>,
        document.body
      )
    : null
}

Modal.Footer = Footer

export default Modal
