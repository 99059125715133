import client from 'api'

interface despositStatusCall {
  txref: string
}

const call = ({ txref }: despositStatusCall) => {
  return client.get(`/gifting/deposit/${txref}/status`)
}

export default call
