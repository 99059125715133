import React, { useState } from 'react'
import { Button, Box } from 'components'
import Table from '../Table'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

interface ExpandableTrProps {
  row: any
  noInnerBorder?: boolean
  rowPadding?: any
  prepareRow: (row: any) => void
  testId?: string
}

const ExpandableTr = ({ row, noInnerBorder, rowPadding, prepareRow, testId }: ExpandableTrProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => setIsExpanded(!isExpanded)
  prepareRow(row)
  return (
    <Table.tr data-testid={testId} {...row.getRowProps()}>
      {row.cells.map((cell: any, i: number) => {
        const shouldDisplay = isExpanded || i === 0
        return (
          <Table.td
            display={[shouldDisplay ? 'flex' : 'none', 'table-cell']}
            key={i}
            borderBottom={noInnerBorder ? 'none' : undefined}
            padding={rowPadding}
            data-label={isExpanded && cell.column.Header}
            {...cell.getCellProps()}
            height="3.5rem"
          >
            <Box textAlign={!isExpanded ? 'left' : undefined}>{cell.render('Cell')}</Box>
            {!isExpanded && (
              <Button display={['flex', 'none']} onClick={toggleExpanded}>
                Expand
                <FiChevronDown size="1.5rem" />
              </Button>
            )}
          </Table.td>
        )
      })}
      {isExpanded && (
        <Button display={['flex', 'none']} width="100%" onClick={toggleExpanded}>
          Collapse
          <FiChevronUp size="1.5rem" />
        </Button>
      )}
    </Table.tr>
  )
}

export default ExpandableTr
