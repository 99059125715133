import { resetPassword, verifyCode, newPassword as callNewPassword } from 'api/generic'

const ResetPasswordStore = () => ({
  phoneNumber: '',
  verificationCode: '',
  newPassword: '',
  counter: '',
  triggerPasswordReset(phoneNumber: string) {
    this.phoneNumber = phoneNumber
    return resetPassword({ phoneNumber: this.phoneNumber })
  },
  resendVerificationCode() {
    return this.triggerPasswordReset(this.phoneNumber)
  },
  triggerCodeVerification(verificationCode: string) {
    this.verificationCode = verificationCode
    return verifyCode({
      phoneNumber: this.phoneNumber,
      verificationCode: this.verificationCode
    })
  },
  setNewPassword(newPassword: string) {
    this.newPassword = newPassword
    return callNewPassword({
      phoneNumber: this.phoneNumber,
      verificationCode: this.verificationCode,
      newPassword: this.newPassword
    })
  },
  setCounter(counter: any) {
    this.counter = counter
  }
})

export default ResetPasswordStore
