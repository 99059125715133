import styled, { css } from 'styled-components/macro'
import { variant } from 'styled-system'
import Box, { BoxProps, boxSystem } from '../Box'
import rgba from 'polished/lib/color/rgba'
import theme from 'theme'

type Variant = 'primary' | 'secondary' | 'cell' | 'error' | ''
type SizeVariant = 'small' | 'large' | ''
type ColorVariant = 'danger'
type BorderVariant = 'none' | 'primary'
type Rounding = 'full' | ''

interface ButtonProps extends BoxProps {
  variant?: Variant
  sizeVariant?: SizeVariant
  colorVariant?: ColorVariant
  borderVariant?: BorderVariant
  rounding?: Rounding
  fullwidth?: boolean
  fontSize?: string
}

const disabledButtonProps = (props: any) => ({
  color: props.theme.colors.text,
  backgroundColor: props.theme.colors.mediumGrey,
  cursor: 'not-allowed',
  border: '1px transparent solid',
  '&:hover': {
    backgroundColor: props.theme.colors.mediumGrey
  }
})

const Button = styled(Box)<ButtonProps>`
  ${boxSystem};
  display: inline-flex;
  border: none;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  transition: box-shadow 0.3s ease-out, background-color 0.3s ease-out, border 0.3s ease-out, color 0.3s ease-out;
  text-transform: capitalize;
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${theme.colors.primary};
  }
  ${(props) =>
    props.fullwidth &&
    css`
      display: flex;
      width: 100%;
    `}
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${(props) =>
    variant({
      variants: {
        primary: {
          color: props.theme.colors.white,
          backgroundColor: props.theme.colors.primary,
          padding: '0.675rem 1.75rem',
          lineHeight: '1.625rem',
          border: `1px transparent solid`,
          borderRadius: props.theme.radii.button,
          boxShadow: `0px 0.25rem 0.375rem ${rgba('#000', 0.1)}`,
          '&:disabled': disabledButtonProps(props),
          '&:hover': {
            color: props.theme.colors.text,
            boxShadow: '0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: props.theme.colors.primary
          }
        },
        error: {
          color: props.theme.colors.red,
          backgroundColor: props.theme.colors.white,
          padding: '0.675rem 1.75rem',
          lineHeight: '1.625rem',
          border: '1px transparent solid',
          boxShadow: '0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.06)',
          borderRadius: props.theme.radii.button,
          '&:disabled': disabledButtonProps(props),
          '&:hover': {
            color: props.theme.colors.text,
            boxShadow: '0 0.125rem 6px 0 rgba(0, 0, 0, 0.12)',
            border: `1px ${props.theme.colors.red} solid`
          }
        },
        secondary: {
          color: props.theme.colors.primary,
          backgroundColor: props.theme.colors.white,
          padding: '0.675rem 1.75rem',
          lineHeight: '1.625rem',
          border: '1px transparent solid',
          boxShadow: '0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.06)',
          borderRadius: props.theme.radii.button,
          '&:disabled': disabledButtonProps(props),
          '&:hover': {
            color: props.theme.colors.text,
            boxShadow: '0 0.125rem 6px 0 rgba(0, 0, 0, 0.12)',
            border: `1px ${props.theme.colors.primary} solid`
          }
        },
        cell: {
          display: 'block',
          color: props.theme.colors.text,
          padding: '1rem 0',
          fontSize: '1rem',
          fontWeight: props.theme.fontWeights.normal,
          textAlign: 'left'
        }
      }
    })}
  ${variant({
    prop: 'sizeVariant',
    variants: {
      small: {
        padding: '0.25rem 1.25rem'
      },
      large: {
        padding: '1.25rem 2.5rem'
      }
    }
  })}
  ${variant({
    prop: 'colorVariant',
    variants: {
      danger: {
        backgroundColor: 'red',
        '&:hover': {
          backgroundColor: 'red',
          color: 'white'
        }
      }
    }
  })}
  ${variant({
    prop: 'borderVariant',
    variants: {
      none: {
        border: '1px transparent solid'
      },
      primary: {
        border: `1px ${theme.colors.primary} solid`
      }
    }
  })}
  ${variant({
    prop: 'rounding',
    variants: {
      full: {
        borderRadius: '2rem'
      }
    }
  })}
`.withComponent('button')

Button.defaultProps = {
  display: 'inline-flex',
  fontWeight: 'medium',
  color: 'primary',
  padding: '0.5rem 0.75rem'
}

export default Button
