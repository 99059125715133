import client from 'api'
import { GENERIC_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (newPasswordParams: Dictionary<string>) => {
  return client.patch(GENERIC_URLS.newPassword, snakeCaseKeys(newPasswordParams))
}

export default call
