const colors = {
  lightPrimary: '#e2fcf6',
  primary: '#0ccd9d',
  text: '#232323',
  greyText: '#2b2b2b',
  darkBackground: '#16171b',
  lightText: '#8f8f8f',
  grey: '#595959',
  borderGrey: '#F2F2F2',
  lightGrey: '#E8E8E8',
  lighterGrey: '#F8F8F8',
  darkGrey: '#898989',
  mediumGrey: '#D4D4D4',
  white: '#ffffff',
  darkNavy: '#3d404d',
  navy: '#1b2342',
  red: '#F26054',
  darkRed: '#eb3526',
  radicalRed: '#fe1f5d',
  yellow: '#fade4d',
  lightYellow: '#feffef',
  lightBlue: '#f6fbff',
  lightGreen: '#0fdbd11a',
  darkGreen: '#0b7661',
  violet: '#abb0f7',
  blue: '#4aabf2',
  darkBlue: '#0a558a',
  iconGrey: '#A4AAB3',
  orange: '#ff9f0a',
  dustyOrange: '#ffa400'
}

const breakpoints: any = ['40em', '52em', '64em', '80em']

const fonts = {
  body: '"Inter", system-ui, sans-serif',
  heading: '"Inter", system-ui, sans-serif'
}

const fontWeights = {
  normal: 400,
  medium: 500,
  semiBold: 600
}

const borders = {
  default: `1px ${colors.lightGrey} solid`,
  card: `1px ${colors.borderGrey} solid`,
  primary: `1px ${colors.primary} solid`,
  primaryWide: `0.25rem ${colors.primary} solid`,
  error: `1px ${colors.red} solid`,
  tab: '3px transparent solid',
  activeTab: `3px ${colors.primary} solid`
}

const radii = {
  small: '0.125rem',
  default: '0.25rem',
  large: '1rem',
  button: '0.25rem'
}

const space = [0, '0.25rem', '0.5rem', '0.75rem', '1rem', '2rem', '4rem']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`
}

const fontSizes = [12, 14, 16, 20, 24, 28, 32] as any
fontSizes.large = fontSizes[6]
fontSizes.medium = fontSizes[4]
fontSizes.small = fontSizes[3]
fontSizes.extraSmall = fontSizes[2]

const shadows = {
  default: '0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.1)',
  darker: '0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.15)'
}

const theme = {
  breakpoints,
  mediaQueries,
  colors,
  fonts,
  fontWeights,
  borders,
  radii,
  space,
  fontSizes,
  shadows
}

export default theme
