import React from 'react'
import { Box } from 'components'
import { formatQuantity } from 'utils/format'

interface ValueChangeProps {
  children?: React.ReactNode
  changePercentage: number | string
  fontSize?: string
  marginTop?: string | number
  justify?: string
  isShort?: boolean
  truncateWidth?: string
}

const ValueChange = ({
  children,
  changePercentage,
  fontSize = '1rem',
  marginTop = '0.5rem',
  justify = 'flex-start',
  isShort = false,
  truncateWidth = 'auto'
}: ValueChangeProps) => {
  const isIncreasing = changePercentage >= 0

  return (
    <Box display="flex" alignItems="center" justifyContent={justify} marginTop={marginTop} fontSize={fontSize}>
      {!isShort && (
        <Box
          color="white"
          padding="0 0.75rem 0 0.25rem"
          fontSize="0.75rem"
          borderRadius="0.125rem"
          backgroundColor={isIncreasing ? 'primary' : 'red'}
          fontWeight="semiBold"
          lineHeight="1.25rem"
          textAlign="center"
        >
          {isIncreasing ? 'UP' : 'DOWN'}
        </Box>
      )}
      <Box
        marginLeft="0.25rem"
        color={isIncreasing ? 'primary' : 'red'}
        overflow="hidden"
        width={truncateWidth}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        {formatQuantity({ value: parseFloat(changePercentage as string) }) || '0'}%
      </Box>
      <Box marginLeft="0.25rem" color={isIncreasing ? 'primary' : 'red'}>
        {children}
      </Box>
    </Box>
  )
}

export default ValueChange
