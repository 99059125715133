import {
  getAll,
  markAll,
  markOne,
  numberOfUnread,
  dividendData,
  insiderTransactionData,
  splitData
} from 'api/notifications'
import { STATE } from 'constants/state'
import { string } from 'yup'

const NotificationsStore = () => ({
  notifications: [] as Record<any, boolean | string>[],
  nextToken: undefined,
  numberOfUnread: 0,
  notification: {
    type: '',
    data: { id: string }
  },
  showNotificationsModal: false,
  toggleNotificationsModal() {
    this.showNotificationsModal = !this.showNotificationsModal
  },
  async fetchNotifications() {
    const { data } = await getAll()
    this.nextToken = data.next_token
    this.notifications = data.notifications
  },
  async fetchMoreNotifications() {
    const { data } = await getAll(this.nextToken)
    this.nextToken = data.next_token
    this.notifications = [...this.notifications, ...data.notifications]
  },
  async markAllAsRead(onFinishCallback: any) {
    try {
      await markAll()
      this.notifications.forEach((notification) => (notification.read = true))
      onFinishCallback(STATE.SUCCESS)
    } catch {
      onFinishCallback(STATE.ERROR)
    }
    this.numberOfUnread = 0
  },
  markOneAsRead(id: string) {
    this.notifications.forEach((notification) => {
      if (notification.id == id) {
        notification.read = true
      }
    })
    this.numberOfUnread -= 1
    return markOne(id)
  },
  async fetchNumberOfUnread() {
    const { data } = await numberOfUnread()
    this.numberOfUnread = data.number
  },
  async fetchDividendDetails(id: string, notificationType: string) {
    const { data } = await dividendData(id)
    this.notification.data = data
    this.notification.type = notificationType
  },
  async fetchInsiderTransaction(id: string, notificationType: string) {
    const { data } = await insiderTransactionData(id)
    this.notification.data = data
    this.notification.type = notificationType
  },
  async fetchSplitDeatils(id: string, notificationType: string) {
    const { data } = await splitData(id)
    this.notification.data = data
    this.notification.type = notificationType
  },
  setNotificationData(data: any) {
    this.notification.data = data
    this.notification.type = data.action
  }
})

export default NotificationsStore
