import { learn } from 'api/dashboard'

const LearnStore = () => ({
  learnResources: { learn_articles: [{ id: '' }], learn_categories: [{ article_ids: [] }] },
  articles: [],
  articlesAssignedToCategory: [{ name: '', articles: [{ id: '' }] }],
  specificSingleArticle: [],
  nextArticleData: {},
  previousArticleData: {},
  isLoading: {
    learnResources: false
  },
  hasError: {
    learnResources: false
  },
  async loadLearnResources() {
    this.isLoading.learnResources = true
    try {
      const { data } = await learn()
      this.learnResources = data
      this.hasError.learnResources = false
    } catch {
      this.hasError.learnResources = true
    }
  },
  assignArticlesToCategory() {
    const articlesAssignedToCategory: Array<any> = []
    this.learnResources.learn_categories.forEach((category: any) => {
      const categoryArticles: Array<any> = []
      category.article_ids.forEach((article_id: number) => {
        this.learnResources.learn_articles.forEach((article) => {
          if (article_id === parseInt(article.id)) {
            categoryArticles.push(article)
          }
        })
      })
      articlesAssignedToCategory.push({ name: category.name, articles: categoryArticles })
      this.articlesAssignedToCategory = articlesAssignedToCategory
    })
  },
  takeSpecificArticleDetails(articleId: string) {
    let specificSingleArticle: any
    this.learnResources.learn_articles.forEach((article: any) => {
      if (article.id === parseInt(articleId)) {
        specificSingleArticle = article
      }
    })
    this.specificSingleArticle = specificSingleArticle
  },
  setArticleLoaded() {
    this.isLoading.learnResources = false
  },
  setArticles(catName: string) {
    this.articlesAssignedToCategory.forEach((category: any) => {
      if (category.name.match(catName)) {
        return (this.articles = category.articles)
      }
    })
  },
  takeTheSameCatArticles(article_id: string) {
    this.assignArticlesToCategory()
    this.articlesAssignedToCategory.forEach((category) => {
      category.articles.forEach((article) => {
        if (parseInt(article.id) === parseInt(article_id)) {
          const actualIndex = category.articles.findIndex((el) => {
            return parseInt(el.id) === parseInt(article_id)
          })
          this.nextArticleData = { nextIndex: actualIndex + 1, articles: category.articles }
          this.previousArticleData = { previousIndex: actualIndex - 1, articles: category.articles }
        }
      })
    })
  }
})

export default LearnStore
