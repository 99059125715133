import { portfolio, portfolioBreakdown, userCurrencyPortfolio } from 'api/dashboard'
import { myStocks } from 'api/stocks'
import { take } from 'ramda'

const PortfolioStore = () => ({
  portfolio: {} as any,
  userCurrencyPortfolio: {
    buyingPower: null
  },
  breakdown: {},
  myStocks: [],
  myStocksCurrencySymbol: '',
  equityValue: '',
  isLoading: {
    portfolio: false,
    userCurrencyPortfolio: false,
    portfolioBreakdown: false,
    myStocks: false
  },
  hasError: {
    userCurrencyPortfolio: false
  },
  async fetchPortfolio() {
    this.isLoading.portfolio = true
    const { data } = await portfolio()
    this.portfolio = data
    this.isLoading.portfolio = false
  },
  async fetchUserCurrencyPortfolio() {
    this.isLoading.userCurrencyPortfolio = true
    this.hasError.userCurrencyPortfolio = false
    try {
      const { data } = await userCurrencyPortfolio()
      this.userCurrencyPortfolio = data
    } catch {
      this.hasError.userCurrencyPortfolio = true
    } finally {
      this.isLoading.userCurrencyPortfolio = false
    }
  },
  async fetchPortfolioBreakdown() {
    this.breakdown = {}
    this.isLoading.portfolioBreakdown = true
    const { data } = await portfolioBreakdown()
    this.breakdown = data
    this.isLoading.portfolioBreakdown = false
  },
  async fetchMyStocks() {
    this.isLoading.myStocks = true
    const {
      data: { stocks, currency_symbol, equity_value }
    } = await myStocks()
    this.myStocks = stocks
    this.myStocksCurrencySymbol = currency_symbol
    this.equityValue = equity_value
    this.isLoading.myStocks = false
  },
  get myStocksShortList() {
    return take(5, this.myStocks)
  }
})

export default PortfolioStore
