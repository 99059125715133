import client from 'api'
import { GENERIC_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (referralSourceParams: Dictionary<any>) => {
  return client.post(GENERIC_URLS.referralSource, snakeCaseKeys(referralSourceParams))
}

export default call
