import styled, { css } from 'styled-components/macro'
import Box, { boxSystem, BoxProps } from '../Box'

const Card = styled(Box)<BoxProps>`
  ${boxSystem};
  transition: box-shadow 0.3s ease-out;
  ${(props) =>
    props.hover &&
    css`
      '&:hover': ${props.hover};
    `};
`

Card.defaultProps = {
  borderRadius: 'default',
  backgroundColor: 'white',
  padding: '1rem',
  boxShadow: 'default'
}

export default Card
