import client from 'api/index'
import { WITHDRAW_URLS } from 'constants/api'
import snakeCaseKeys from 'snakecase-keys'
import { WithdrawParams } from 'types/withdraw'

const call = (withdrawParams: WithdrawParams) => {
  return client.post(WITHDRAW_URLS.withdraw, snakeCaseKeys(withdrawParams))
}

export default call
