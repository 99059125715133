import client from 'api'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'
import { USER_URLS } from 'constants/api'

interface RefreshToken {
  refreshTokenParams: Dictionary<string>
}

const call = ({ refreshTokenParams }: RefreshToken) => {
  return client.post(USER_URLS.refreshToken, snakeCaseKeys(refreshTokenParams))
}

export default call
