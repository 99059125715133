import styled from 'styled-components/macro'
import Box, { boxSystem } from '../Box'

const Heading = styled(Box)`
  ${boxSystem};
`.withComponent('h1')

Heading.defaultProps = {
  fontFamily: 'heading',
  fontWeight: 'semiBold',
  fontSize: 'small',
  color: 'text',
  lineHeight: '1.625rem'
}

export default Heading
