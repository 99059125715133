import client from 'api'
import { GENERIC_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (investmentProfileParams: Dictionary<any>) => {
  return client.post(GENERIC_URLS.investmentProfile, snakeCaseKeys(investmentProfileParams))
}

export default call
