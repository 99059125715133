import Box from '../Box'
import Button from '../Button'
import React from 'react'
import { Spinner } from '../index'

const StickyWizardButton = ({ children, isLoading, ...rest }: any) => {
  return (
    <>
      <Box
        display={['block', 'none']}
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        padding="0 1.5rem 1.5rem 1.5rem"
        backgroundColor="white"
      >
        <Button
          variant="primary"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth="12rem"
          fullwidth
          rounding="slight"
          data-testid="formSubmit"
          disabled={isLoading}
          {...rest}
        >
          {isLoading && <Spinner color="primary" />}
          {children}
        </Button>
      </Box>
      <Box display={['none', 'block']} marginTop={6}>
        <Button
          variant="primary"
          sizeVariant="large"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth="12rem"
          data-testid="formSubmit"
          disabled={isLoading}
          {...rest}
        >
          {isLoading && <Spinner color="primary" />}
          {children}
        </Button>
      </Box>
    </>
  )
}

export default StickyWizardButton
