import { withdrawData, withdraw, calculate, userCurrencyWithdraw } from 'api/withdraw'
import { WithdrawParams } from 'types/withdraw'

const WithdrawalStore = () => ({
  withdrawData: { withdrawal_methods: [] },
  withdrawType: '',
  isLoading: {
    withdrawData: false,
    submitWithdraw: false
  },
  hasError: {
    withdrawData: false
  },
  async fetchWithdrawData() {
    this.isLoading.withdrawData = true
    const { data } = await withdrawData()
    this.withdrawData = data
    this.isLoading.withdrawData = false
  },
  async submitWithdraw({ handleError, handleSuccess, ...withdrawParams }: WithdrawParams & any) {
    this.isLoading.submitWithdraw = true
    try {
      await withdraw(withdrawParams)
      handleSuccess()
    } catch (error) {
      handleError(error.response?.data.message)
    }
    this.isLoading.submitWithdraw = false
  },
  setWithdrawType(withdrawType: string) {
    this.withdrawType = withdrawType
  },
  get chosenWithdrawalMethod() {
    return this.withdrawData.withdrawal_methods.find((method: any) => method.box_title === this.withdrawType)
  }
})

export default WithdrawalStore
