export const ORDER_TYPES = {
  MARKET: 'MARKET',
  STOP: 'STOP',
  LIMIT: 'LIMIT'
}

export const ORDER_SIDES = {
  BUY: 'BUY',
  SELL: 'SELL'
}
