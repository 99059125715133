import client from 'api/index'
import { Dictionary } from 'types'

export interface Profile {
  profileParams: Dictionary<string>
}

const call = ({ profileParams }: Profile) => {
  return client.patch('profile', profileParams)
}

export default call
