import { documents, downloadDocument } from 'api/dashboard'

const DocumentsStore = () => ({
  documentsList: [],
  downloadedDocument: '',

  isLoading: {
    documentsList: false,
    downloadedDocument: false
  },

  hasError: {
    documentsList: false,
    downloadedDocument: false
  },

  async fetchDocuments({ documentsType, errorOccured }: { documentsType: string; errorOccured: any }) {
    this.isLoading.documentsList = true
    try {
      const { data } = await documents(documentsType)
      this.documentsList = data.documents
    } catch {
      this.hasError.documentsList = true
      errorOccured()
    } finally {
      this.isLoading.documentsList = false
    }
  },

  async downloadDocument({ fileKey, errorOccured }: { fileKey: string; errorOccured: any }) {
    this.isLoading.downloadedDocument = true
    try {
      const { data } = await downloadDocument(fileKey)
      this.downloadedDocument = data.url
    } catch {
      this.hasError.downloadedDocument = true
      errorOccured()
    } finally {
      this.isLoading.downloadedDocument = false
    }
  }
})

export default DocumentsStore
