import client from 'api/index'
import { ORDERS_URLS } from 'constants/api'
import snakeCaseKeys from 'snakecase-keys'
import { OrderParams } from 'types/orders'

const call = (orderParams: OrderParams) => {
  return client.post(ORDERS_URLS.order, snakeCaseKeys(orderParams))
}

export default call
