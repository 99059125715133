type value = number

interface FormatCurrencyArgs {
  value: value
  currency?: string
}

/** Formats currency, ex. 304323.5 -> $304,323.50 */
export const formatCurrency = ({ value, currency }: FormatCurrencyArgs) => {
  const intl = currency === 'NGN' ? 'en-NG' : 'en-US'
  const formatter = new Intl.NumberFormat(intl, {
    style: 'currency',
    currency: currency || 'USD'
  })
  return formatter.format(value || 0)
}

interface FormatQuantityArgs {
  value: value
}

/** Formats quantity and round the decimal, ex. 0.433255 -> 0.43 */
export const formatQuantity = ({ value }: FormatQuantityArgs) => {
  return value ? parseFloat(value.toFixed(2)) : null
}

/** Formats number as a compact value - ex. 23000000 -> $23.3M  */
export const formatCompact = ({ value }: FormatQuantityArgs) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    // Why: https://github.com/microsoft/TypeScript/issues/36533#issuecomment-607081387
    // @ts-ignore
    notation: 'compact',
    // @ts-ignore
    compactDisplay: 'short'
  })
  return formatter.format(value)
}

interface unmaskCurrencyProps {
  value: string
}

/** Unmasks value coming from MonetaryInput component */
export const unmaskCurrency = ({ value }: unmaskCurrencyProps) => {
  if (!value) return
  const unmaskedValue = String(value)?.replace(/[^\d.]+/g, '')
  return parseFloat(unmaskedValue)
}
