const Index = () => ({
  previousRouteUrl: '/',
  progress: {
    steps: 0,
    currentStep: 0
  },
  setPreviousRouteUrl(url: string) {
    this.previousRouteUrl = url
  },
  setProgressStepsAmount(progress: number) {
    this.progress.steps = progress
  },
  setProgressStep(progress: number) {
    this.progress.currentStep = progress
  }
})

export default Index
