import { featuredStocks, recentNews, portfolioGraph } from 'api/dashboard'
import { take, apply } from 'ramda'
import { Dictionary } from 'types'
import { splitEvery } from 'ramda'

const DashboardStore = () => ({
  invest: {
    featuredStocks: [],
    featuredStocksCurrencySymbol: '',
    recentNews: [],
    portfolioGraph: [],
    dateRange: '1m',
    paginationPerSide: 8,
    paginatedNewsCounter: 8,
    isLoading: {
      featuredStocks: false,
      portfolioGraph: false,
      recentNews: false
    }
  },
  async fetchFeaturedStocks() {
    if (this.invest.featuredStocks.length > 0) return
    this.invest.isLoading.featuredStocks = true
    const {
      data: { featured_stocks, currency_symbol }
    } = await featuredStocks()
    this.invest.featuredStocks = featured_stocks
    this.invest.featuredStocksCurrencySymbol = currency_symbol
    this.invest.isLoading.featuredStocks = false
  },
  async fetchRecentNews() {
    if (this.invest.recentNews.length > 0) return
    this.invest.isLoading.recentNews = true
    const {
      data: { news }
    } = await recentNews()
    this.invest.recentNews = news
    this.invest.isLoading.recentNews = false
  },
  async fetchPortfolioGraph(dateRange: string) {
    if (dateRange === this.invest.dateRange) return
    this.invest.isLoading.portfolioGraph = true
    const {
      data: { data: graphEntries }
    } = await portfolioGraph(dateRange)
    this.invest.portfolioGraph = graphEntries
    this.invest.isLoading.portfolioGraph = false
  },
  setDateRange(dateRange: string) {
    this.invest.dateRange = dateRange
  },
  loadMoreRecentNews() {
    this.invest.paginatedNewsCounter += this.invest.paginationPerSide
  },
  get portfolioGraphMin() {
    const values = this.invest.portfolioGraph?.map((el: Dictionary<number>) => el.value)
    return apply(Math.min, values)
  },
  get featuredStocksSplittedList() {
    return splitEvery(4, this.invest.featuredStocks) as any
  },
  get recentNewsShortList() {
    return take(8, this.invest.recentNews)
  },
  get limitedRecentNews() {
    return take(this.invest.paginatedNewsCounter, this.invest.recentNews)
  }
})

export default DashboardStore
