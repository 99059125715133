import styled, { css } from 'styled-components/macro'
import Box, { BoxProps, boxSystem } from '../Box'

interface TextProps extends BoxProps {
  isTruncated?: boolean
}

const Text = styled(Box)<TextProps>`
  ${boxSystem};
  ${(props: TextProps) =>
    props.isTruncated &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`.withComponent('p')

Text.defaultProps = {
  color: 'text',
  fontFamily: 'body',
  fontWeight: 'regular',
  lineHeight: '1.625rem',
  letterSpacing: 0
}

export default Text
