import { getPersisted } from 'store/UserStore'

interface UserDataProps {
  email: string
  name: string
  surname: string
}

export const setIntercomData = (userData: UserDataProps) => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  Intercom('shutdown')
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APPID,
    email: userData.email,
    name: `${userData.name} ${userData.surname}`,
    user_id: getPersisted('user-id', null),
    hide_default_launcher: true
  }) as any
}

export const showIntercom = () => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  Intercom('show') as any
}
