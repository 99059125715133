import React, { Suspense, lazy } from 'react'
import { Router, Redirect } from '@reach/router'
import ScrollToTop from 'utils/ScrollToTop'

/* Common Domain */
import LoadingOverlay from 'domains/Common/components/LoadingOverlay'
import PaymentRedirectView from 'domains/Common/views/PaymentRedirect'
import AcceptGiftView from 'domains/Common/views/AcceptGift'
import DepositRedirect from 'domains/Common/views/DepositRedirect'
const NotFoundView = lazy(() => import('domains/Common/views/NotFound'))
const InvitationRedirectView = lazy(() => import('domains/Common/views/InvitationRedirect'))
const EmailVerificationRedirectView = lazy(() => import('domains/Common/views/VerifyEmail'))
/* Login Domain */
const LoginDomain = lazy(() => import('domains/Login'))
const LoginView = lazy(() => import('domains/Login/views/Login'))
const ResetPasswordView = lazy(() => import('domains/Login/views/ResetPassword'))
const ResetVerificationView = lazy(() => import('domains/Login/views/ResetVerification'))
const SetNewPasswordView = lazy(() => import('domains/Login/views/SetNewPassword'))
/* SignUp Domain */
const SignUpDomain = lazy(() => import('domains/SignUp'))
const PersonalDetailsStep = lazy(() => import('domains/SignUp/views/PersonalDetailsStep'))
const VerifyPhoneStep = lazy(() => import('domains/SignUp/views/VerifyPhoneStep'))
const PermanentAddressStep = lazy(() => import('domains/SignUp/views/PermanentAddressStep'))
const BankVerificationNumberStep = lazy(() => import('domains/SignUp/views/BankVerificationNumberStep'))
const SuccessPage = lazy(() => import('domains/SignUp/views/SuccessPage'))
const FixedReturnsSuccessPage = lazy(() => import('domains/SignUp/views/FixedReturnSuccessPage'))
/* Dashboard Domain */
const DashboardDomain = lazy(() => import('domains/Dashboard'))
const HomeView = lazy(() => import('domains/Dashboard/views/Home'))
const WatchlistView = lazy(() => import('domains/Dashboard/views/Watchlist'))
const SearchView = lazy(() => import('domains/Dashboard/views/Search'))
const LearnView = lazy(() => import('domains/Dashboard/views/Learn'))
const LearnArticleView = lazy(() => import('domains/Dashboard/views/LearnArticle'))
const SettingsView = lazy(() => import('domains/Dashboard/views/Settings'))
const StockView = lazy(() => import('domains/Dashboard/views/Stock'))
const MyStocks = lazy(() => import('domains/Dashboard/views/MyStocks'))
const GiftingSuccessView = lazy(() => import('domains/Dashboard/views/GiftingSuccess'))
const DocumentsView = lazy(() => import('domains/Dashboard/views/Documents'))
const InvestmentProfileView = lazy(() => import('domains/Dashboard/views/InvestmentProfile'))
const AffiliationsView = lazy(() => import('domains/Dashboard/views/Affiliations'))
const ThemeView = lazy(() => import('domains/Dashboard/views/Theme'))
const OrderDetailsView = lazy(() => import('domains/Dashboard/views/OrderDetails'))
const ViolationsView = lazy(() => import('domains/Dashboard/views/GoodFaithViolation'))
const ActivityView = lazy(() => import('domains/Dashboard/views/Activity'))
const RecentNewsView = lazy(() => import('domains/Dashboard/views/RecentNews'))
const NotificationsView = lazy(() => import('domains/Dashboard/views/Notifications'))
const StockNewsView = lazy(() => import('domains/Dashboard/views/StockNews'))
const RestrictedView = lazy(() => import('domains/Common/views/Restricted'))
const AnnualReportView = lazy(() => import('domains/Dashboard/views/AnnualReport'))
/* FixedReturns */
const FixedReturnsDomain = lazy(() => import('domains/FixedReturns'))
const InvestView = lazy(() => import('domains/FixedReturns/views/Invest'))
const InvestSuccessView = lazy(() => import('domains/FixedReturns/views/SuccessPage'))

const Routing = () => (
  <Suspense fallback={<LoadingOverlay />}>
    <Router>
      <ScrollToTop path="/">
        <Redirect from="/" to="/dashboard" noThrow />
        <Redirect from="/stock/:symbol" to="/dashboard/stock/:symbol" noThrow />
        <DepositRedirect path="/account/deposit" />
        <PaymentRedirectView path="/payment_redirect" />
        <AcceptGiftView path="/accept_gift" />
        <InvitationRedirectView path="invite/:referralCode" />
        <EmailVerificationRedirectView path="verify_email" />
        <RestrictedView path="/restricted" />
        <LoginDomain path="login">
          <LoginView path="/" />
          <ResetPasswordView path="/reset" />
          <ResetVerificationView path="/reset/verification" />
          <SetNewPasswordView path="/reset/password" />
        </LoginDomain>
        <SignUpDomain path="signup">
          <PersonalDetailsStep path="/" />
          <VerifyPhoneStep path="verify" />
          <BankVerificationNumberStep path="bvn" />
          <PermanentAddressStep path="address" />
          <SuccessPage path="success" />
          <FixedReturnsSuccessPage path="fixed-returns-success" />
        </SignUpDomain>
        <DashboardDomain path="dashboard">
          <HomeView path="/" />
          <WatchlistView path="watchlist" />
          <SearchView path="search" />
          <LearnView path="learn" />
          <LearnArticleView path="learn/:articleId" />
          <SettingsView path="settings" />
          <StockView path="stock/:symbol" />
          <MyStocks path="invested" />
          <NotFoundView default />
          <GiftingSuccessView path="/gifting/success" />
          <DocumentsView path="/account/documents/:documentType" />
          <InvestmentProfileView path="investmentprofile" />
          <AffiliationsView path="affiliations" />
          <ThemeView path="theme/:themeId" />
          <OrderDetailsView path="order/:orderId" />
          <ViolationsView path="violations" />
          <ActivityView path="activity" />
          <RecentNewsView path="news" />
          <StockNewsView path="news/:symbol" />
          <NotificationsView path="notifications" />
          <AnnualReportView path="report" />
        </DashboardDomain>
        <FixedReturnsDomain path="fixed-returns">
          <InvestView path="invest" />
          <InvestSuccessView path="success" />
        </FixedReturnsDomain>
        <NotFoundView default />
      </ScrollToTop>
    </Router>
  </Suspense>
)

export default Routing
