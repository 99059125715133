import client from '../'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'

interface PhoneVerification {
  phoneVerificationParams: Dictionary<string>
}

const call = ({ phoneVerificationParams }: PhoneVerification) => {
  return client.post('verify_phone_number', snakeCaseKeys(phoneVerificationParams))
}

export default call
