import client from 'api'
import { GENERIC_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (verifyCodeParams: Dictionary<string>) => {
  return client.post(GENERIC_URLS.verifyCode, snakeCaseKeys(verifyCodeParams))
}

export default call
