import React from 'react'
import Box, { boxSystem, BoxProps } from '../Box'
import styled, { css } from 'styled-components/macro'
import InputAppearance, { Icon } from '../mixins/InputAppearance'

interface InputProps extends BoxProps {
  hasError?: boolean
  hasIcon?: boolean
  readOnly?: boolean
  onChange?: React.FormEvent<HTMLInputElement>
  isSmall?: boolean
  name?: string
}

const Input = styled(Box)<InputProps>`
  ${boxSystem};
  ${InputAppearance};
  ${(props) =>
    props.hasIcon &&
    `
    padding-left: 3rem;
  `}
  ${(props) =>
    props.name === 'bvn' &&
    `::-webkit-inner-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }  
  `}
  ${(props) =>
    props.isSmall &&
    css`
      padding: 0.5rem;
      font-size: 0.75rem;
    `}
  ${(props) =>
    props.isSmall &&
    props.hasIcon &&
    css`
      padding-left: 1.5rem;
    `}
`.withComponent('input')

const AlertIcon = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M0 8a8 8 0 1116 0A8 8 0 010 8z" fill="#F27474" />
      <path d="M8 4v4M8 11.7h0" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 8a8 8 0 1116 0A8 8 0 010 8z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)

interface ComponentProps {
  hasError?: boolean
  IconComponent?: any
  readOnly?: boolean
  [x: string]: any
}

export default React.forwardRef((props: ComponentProps, ref?: any) => {
  const { hasError, IconComponent, placeholder, hasLabel = true, onChange, isSmall, name, ...rest } = props
  const handleKeyDown = (event: React.KeyboardEvent & React.BaseSyntheticEvent<HTMLInputElement>) => {
    if (event.keyCode === 27) {
      return event.target?.blur()
    }
  }
  return (
    <Box position="relative" className="b-input">
      <Input
        hasError={!!hasError}
        hasIcon={!!IconComponent}
        placeholder={placeholder}
        onChange={onChange}
        isSmall={isSmall}
        onKeyDown={handleKeyDown}
        className="b-input__element"
        ref={ref}
        name={name}
        {...rest}
      />
      {!!IconComponent && (
        <Icon>
          <IconComponent />
        </Icon>
      )}
      {!!hasError && (
        <Icon position="right">
          <AlertIcon />
        </Icon>
      )}
    </Box>
  )
})
