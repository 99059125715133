import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import Heading from '../Heading'
import Text from '../Text'
import Button from '../Button'

interface AlertProps {
  isVisible: boolean
  toggle: () => void
  title: string
  description?: string
  primaryAction: {
    label?: string
    onClick: () => void
  }
  secondaryAction?: {
    label?: string
    onClick?: () => void
  }
}

const Alert = ({ isVisible, toggle, title, description, primaryAction, secondaryAction }: AlertProps) => {
  const { t } = useTranslation()

  return (
    <Modal isVisible={isVisible} onHide={toggle}>
      <Heading size="small" as="h3" marginTop={0}>
        {title}
      </Heading>
      {description && <Text>{description}</Text>}
      <Modal.Footer>
        <Button variant="secondary" onClick={secondaryAction?.onClick || toggle} sizeVariant="small">
          {secondaryAction?.label || t('Common:Cancel')}
        </Button>
        <Button variant="primary" ml="1rem" onClick={primaryAction.onClick} sizeVariant="small" colorVariant="danger">
          {primaryAction.label || t('Common:OK')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Alert
