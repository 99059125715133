import Box, { boxSystem, BoxProps } from '../Box'
import styled, { css } from 'styled-components/macro'
import theme from 'theme'

export const Error = styled(Box).attrs({
  'data-testid': 'formError'
})``

Error.defaultProps = {
  display: 'block',
  fontSize: '0.75rem',
  fontFamily: 'body',
  fontWeight: 'regular',
  marginTop: '0.2rem',
  color: 'red',
  height: '19px'
}

interface FormControlProps extends BoxProps {
  hasError?: any
}

const FormControl = styled.div<FormControlProps>`
  ${boxSystem};
  &:focus-within {
    .b-label {
      color: ${theme.colors.primary};
    }
  }
  ${(props) =>
    props.hasError &&
    css`
      .b-label {
        color: ${theme.colors.red};
      }
    `}
`

FormControl.defaultProps = {
  marginBottom: '1rem',
  position: 'relative'
}

export default FormControl
