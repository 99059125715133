export const GENERIC_URLS = {
  dictionary: 'dictionary',
  exchangeRate: (currency = 'NGN') => `exchange_rate?currency=${currency}`,
  investmentProfile: 'investment_profile',
  registrationStep: 'registration_step',
  affiliations: 'affiliations',
  referralSourceOptions: 'referral_source/options',
  referralSource: 'referral_source',
  monthChoice: 'month_choice',
  themes: 'themes',
  themeStocks: (id: string) => `themes/${id}/stocks`,
  acceptGift: 'accept_gift',
  resetPassword: 'reset_password',
  verifyCode: 'verify_code',
  newPassword: 'new_password',
  pendingOrders: 'pending_orders',
  activity: 'activity',
  verifyEmail: 'verify_email',
  verifyIdentityIdentifier: 'verify_identity_identifier',
  featureFlags: 'feature_flags'
}

export const STOCKS_URLS = {
  myStocks: 'my_stocks',
  details: (symbol: string) => `stock/${symbol}/details`,
  graph: (symbol: string) => `stock/${symbol}/graph`,
  whyIsItMoving: (symbol: string) => `stock/${symbol}/why_is_it_moving`,
  analystRating: (symbol: string) => `stock/${symbol}/analyst_rating`,
  news: (symbol: string) => `stock/${symbol}/news`,
  favorite: (symbol: string) => `stock/${symbol}/favourite`,
  watchlist: 'watchlist',
  basic: (symbol: string) => `stock/${symbol}/basic`,
  financialStats: (symbol: string) => `stock/${symbol}/financial_stats`,
  ownership: (symbol: string) => `stock/${symbol}/ownership`,
  about: (symbol: string) => `stock/${symbol}/about`
}

export const USER_URLS = {
  refreshToken: 'refresh_token',
  profile: {
    fullName: 'profile/fullname',
    password: 'profile/password',
    resendVerificationEmail: 'profile/resend_email'
  },
  external_links: '/external_links'
}

export const PORTFOLIO_URLS = {
  get: 'portfolio',
  breakdown: 'portfolio/breakdown',
  graph: 'portfolio/graph',
  userCurrencyPortfolio: 'user_currency/portfolio'
}

export const ORDERS_URLS = {
  order: 'order',
  calculate: 'order/calculate',
  cancel: (id: number) => `order/${id}/cancel`,
  status: (id: string) => `order/${id}/status`
}

export const ALERTS_URLS = {
  create: 'alerts/stock',
  getAll: 'alerts/stock',
  delete: (id: number) => `alerts/stock/${id}`
}

export const TRANSFERS_URLS = {
  calculate: (amount: string | number) =>
    `user_currency/transfer/user_currency_to_usd_wallet/calculate?amount=${amount}`,
  userCurrencyToUsdWallet: 'user_currency/transfer/user_currency_to_usd_wallet'
}

export const DEPOSIT_URLS = {
  depositMethods: 'deposit/deposit_methods',
  paymentMethods: 'deposit/payment_methods',
  deposit: 'deposit',
  status: (txref: string) => `deposit/${txref}/status`,
  USER_CURRENCY: {
    paymentMethods: (amount: string) => `user_currency/deposit/payment_methods?amount=${amount}`
  },
  instantDepositAvailability: '/deposit/instant_deposit_availability'
}

export const WITHDRAW_URLS = {
  withdrawData: 'v2/withdrawal_methods',
  withdraw: 'withdraw',
  userCurrencyWithdrawal: 'user_currency/withdrawal',
  userCurrencyWithdrawalCalculate: 'user_currency/withdrawal/calculate'
}

export const INVITE_URLS = {
  referalLink: 'referral',
  referrals: 'referrals',
  verifyReferal: 'verify_referral_code'
}

export const MOBILE_SEARCH_URLS = {
  themes: 'themes',
  themeStocks: (theme_id: number) => `themes/${theme_id}/stocks`
}

export const MARKET_URLS = {
  openDate: 'market/open_date'
}

export const NEXT_OF_KIN_URLS = {
  nextOfKin: 'next_of_kin',
  relationships: 'next_of_kin/relationships'
}

export const NOTIFICATIONS_URLS = {
  index: 'notification',
  markAll: 'notification/mark_all_as_read',
  markOne: (notification_id: string) => `notification/${notification_id}/mark_as_read`,
  numberOfUnread: 'notification/number_of_unread',
  dividendData: (dividend_id: string) => `dividend/${dividend_id}`,
  insiderTransactionData: (insider_transaction_id: string) => `insider_transaction/${insider_transaction_id}`,
  splitData: (split_id: string) => `split/${split_id}`,
  settings: 'notification/settings'
}

export const TOP_MOVERS_URL = {
  topMovers: '/top_mover_stocks'
}

export const FIXED_RETURNS_URL = {
  fixedReturns: '/fixed_investment/create_hni',
  plans: '/fixed_investment/available'
}

export const EXCHANGE_RATE_URL = {
  exchangeRate: '/exchange_rate?currency=NGN'
}
