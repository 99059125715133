import { referralLink, referrals, verifyReferral } from 'api/dashboard'

const InvitationStore = () => ({
  step: 0,
  referrals: {},
  referralLink: '',
  isReferralCodeValid: true,

  isLoading: {
    referrals: false,
    referralLink: false
  },

  hasError: {
    referrals: false,
    referralLink: false,
    isReferralCodeValid: false
  },

  setStep(step: number) {
    this.step = step
  },

  async fetchReferrals() {
    this.isLoading.referrals = true
    try {
      const { data } = await referrals()
      this.referrals = data
    } catch (error) {
      this.hasError.referrals = true
    } finally {
      this.isLoading.referrals = false
    }
  },

  async fetchReferralLink() {
    if (this.referralLink) return
    this.isLoading.referralLink = true
    try {
      const { data } = await referralLink()
      this.referralLink = data
    } catch (error) {
      this.hasError.referralLink = true
    } finally {
      this.isLoading.referralLink = false
    }
  },

  async verifyReferralCode({ referralCode, errorOccured }: { referralCode: string; errorOccured: any }) {
    try {
      await verifyReferral(referralCode)
    } catch (error) {
      this.hasError.referralLink = false
      errorOccured(error.response?.data.message)
    }
  }
})

export default InvitationStore
