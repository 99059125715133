import { calculate, userCurrencyToUsdWallet } from 'api/transfer'

const DepositStore = () => ({
  usdWalletTransfer: {
    amount: 0
  },
  usdWalletTransferCalculation: {},
  isLoading: {
    usdWalletTransferCalculation: false,
    transferFromNgnToUsdWallet: false
  },
  hasError: {
    usdWalletTransferCalculation: false,
    transferFromNgnToUsdWallet: false
  },
  setUsdWalletTransferAmount(amount: number) {
    this.usdWalletTransfer.amount = amount
  },
  async fetchUsdWalletTransferCalculation() {
    this.isLoading.usdWalletTransferCalculation = true
    this.hasError.usdWalletTransferCalculation = false
    const amount = parseFloat(String(this.usdWalletTransfer.amount)).toFixed(2)
    const { data } = await calculate({ amount })
    this.usdWalletTransferCalculation = data
    this.isLoading.usdWalletTransferCalculation = false
  },
  async transferFromNgnToUsdWallet() {
    this.isLoading.transferFromNgnToUsdWallet = true
    this.hasError.transferFromNgnToUsdWallet = false
    const amount = parseFloat(String(this.usdWalletTransfer.amount))
    await userCurrencyToUsdWallet({ amount })
    this.isLoading.transferFromNgnToUsdWallet = false
  }
})

export default DepositStore
