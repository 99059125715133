import client from 'api'
import { AxiosResponse } from 'axios'
import { FIXED_RETURNS_URL } from 'constants/api'
export interface IPlans {
  timeframe_in_days: number
  id: number
  annual_percent_interest: number
}

export interface IFixedReturnsPlans {
  currency_symbol: string
  predefined_plans: IPlans[]
}
const call = () => {
  return client.get<any, AxiosResponse<IFixedReturnsPlans>>(FIXED_RETURNS_URL.plans)
}

export default call
