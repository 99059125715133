import client from '../'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'

interface Register {
  userParams: Dictionary<string>
}

const call = ({ userParams }: Register) => {
  return client.post('register', snakeCaseKeys(userParams))
}

export default call
