import styled, { css } from 'styled-components/macro'
import { motion } from 'framer-motion'
import {
  compose,
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps
} from 'styled-system'

interface Ref {
  ref?: any
}

export interface BoxProps
  extends BackgroundProps,
    BorderProps,
    ColorProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    TypographyProps,
    Ref {
  hover?: any
  bgSplash?: any
}

export const boxSystem = compose(background, border, color, flexbox, grid, layout, position, shadow, space, typography)

const Box = styled(motion.div)<BoxProps>`
  ${boxSystem};
  box-sizing: border-box;
  transition: ${(props) => `background-color 0.3s ease-out, ${props.bgSplash}`};
  ${(props) =>
    props.hover &&
    css`
      &:hover {
        ${props.hover}
      }
    `};
`

Box.defaultProps = {
  color: 'text',
  fontFamily: 'body',
  lineHeight: '1.625rem',
  letterSpacing: 0
}

export default Box
