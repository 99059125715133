import client from 'api'
import { NOTIFICATIONS_URLS } from 'constants/api'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'

const call = (settingsParams: Dictionary<boolean | number>) => {
  return client.patch(NOTIFICATIONS_URLS.settings, snakeCaseKeys(settingsParams))
}

export default call
