import client from '../'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'

interface SignAgreements {
  agreementsParams: Dictionary<string>
}

const call = ({ agreementsParams }: SignAgreements) => {
  return client.post('agreements', snakeCaseKeys(agreementsParams))
}

export default call
