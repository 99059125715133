import React from 'react'
import styled from 'styled-components/macro'
import {
  ControlContainer,
  HiddenSelectableAppearance,
  SelectableAppearance,
  SelectableAppearanceActive,
  Label,
  ComponentProps
} from '../mixins/SelectableAppearance'

interface CheckboxProps {
  checked?: boolean
  hasSmallLabel?: boolean
}

const StyledCheckbox = styled.label<CheckboxProps>`
  ${SelectableAppearance};
  border-radius: ${(props) => props.theme.radii.default};
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${HiddenSelectableAppearance};
  &:checked + ${StyledCheckbox} {
    ${SelectableAppearanceActive};
  }
`

export default React.forwardRef((props: ComponentProps, ref?: any) => {
  const { id, children, checked, value, onChange, hasSmallLabel, ...rest } = props
  return (
    <ControlContainer>
      <HiddenCheckbox id={id} checked={checked} value={value} onChange={onChange} ref={ref} readOnly {...rest} />
      <StyledCheckbox htmlFor={id} checked={checked} onClick={onChange} />
      {children && (
        <Label onClick={onChange} fontSize={hasSmallLabel ? '14px' : undefined} htmlFor={id}>
          {children}
        </Label>
      )}
    </ControlContainer>
  )
})
