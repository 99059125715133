import { stockSearch, themes, themeStocks } from 'api/dashboard'
import { StockSearch } from 'api/dashboard/stockSearch'
import { watchlist as watchlistApi } from 'api/dashboard'
import { PLACEHOLDER_TYPE } from 'constants/watchlist'

const StockSearchStore = () => ({
  query: '',
  themeId: '',
  filterParams: '',
  filteredList: [],
  themes: [],
  themeStocks: { featured_theme_stocks: [] },
  results: [],
  watchlist: [],
  watchlistCurrencySymbol: '',
  isLoading: {
    results: false,
    themes: false,
    themeStocks: false,
    watchlist: false
  },
  hasError: {
    results: false,
    watchlist: false
  },

  setThemeId(themeId: any) {
    this.themeId = themeId
  },

  setFilterParams(filterParams: string) {
    this.filterParams = filterParams
  },

  setFilteredList(filteredList: any) {
    this.filteredList = filteredList
  },

  async fetchResults({ query, themeId, filterParams, handleError }: StockSearch) {
    this.isLoading.results = true
    this.hasError.results = false
    try {
      const {
        data: { result }
      } = await stockSearch({ query, themeId, filterParams })
      this.results = result
    } catch (e) {
      handleError(e)
      this.hasError.results = true
    }

    this.isLoading.results = false
  },

  async fetchThemes() {
    this.isLoading.themes = true
    const { data } = await themes()
    this.themes = data.featured_themes
    this.isLoading.themes = false
  },

  async fetchThemeStocks(themeId: number) {
    this.isLoading.themeStocks = true
    this.hasError.results = false
    const { data } = await themeStocks(themeId)
    this.themeStocks = data
    this.results = []
    this.results = data.featured_theme_stocks
    this.isLoading.themeStocks = false
  },

  filterThemeStocks(query: string) {
    const filteredThemeStocks: any = []
    this.themeStocks.featured_theme_stocks?.forEach((el: any) => {
      if (el.name.toLowerCase().includes(query.toLowerCase())) {
        filteredThemeStocks.push(el)
      }
    })
    this.results = filteredThemeStocks
  },

  clearSearchStore() {
    this.query = ''
    this.themes = []
    this.themeStocks = { featured_theme_stocks: [] }
    this.results = []
    this.filterParams = ''
  },

  changeQuery(query: string) {
    this.query = query
  },

  async fetchWatchlist() {
    this.isLoading.watchlist = true
    this.hasError.results = false
    const {
      data: { watchlist, currency_symbol }
    } = await watchlistApi({
      ...(this.themeId && { themeId: this.themeId }),
      ...(this.filterParams && { filterParams: this.filterParams })
    })
    this.watchlist = watchlist
    this.watchlistCurrencySymbol = currency_symbol
    this.isLoading.watchlist = false
  },
  get filteredWatchlist() {
    const filterByQuery = (element: any) => {
      const query = this.query.toLowerCase()
      const matchesName = element.name.toLowerCase().includes(query)
      const matchesSymbol = element.symbol.toLowerCase().includes(query)
      return matchesName || matchesSymbol
    }
    return this.watchlist.filter(filterByQuery)
  },
  get placeholderType() {
    if (this.filteredWatchlist.length !== 0) return
    if (this.query.length > 0) return PLACEHOLDER_TYPE.STOCK_NOT_FOUND
    return PLACEHOLDER_TYPE.START_BUILDING_WATCHLIST
  }
})

export default StockSearchStore
