import client from 'api'

interface createGiftCall {
  symbol: string
  phone_number: string
  message: string
  amount: number
}

const call = ({ symbol, phone_number, message, amount }: createGiftCall) => {
  return client.post('/gifting/gift', {
    symbol,
    phone_number,
    message,
    amount
  })
}

export default call
