import { verifyReciver, calculateGift, createGift, depositMoney, depositStatus } from 'api/gifting'

const GiftingStore = () => ({
  step: 0,
  verifyReceiverData: { phone_number: '' },
  stockData: { symbol: '' },
  calculatedGift: { quantity: 0 },
  message: '',
  amount: 0,
  paymentMethods: [],
  paymentRedirectData: {},
  depositStatus: {},

  isLoading: {
    verifyReceiverData: false,
    calculatedGift: false,
    paymentMethods: false,
    paymentRedirectData: false,
    depositStatus: false
  },

  hasError: {
    verifyReceiverData: false,
    calculatedGift: false,
    paymentMethods: false,
    paymentRedirectData: false,
    depositStatus: false
  },

  async verifyReciver(phoneNumber: string) {
    this.isLoading.verifyReceiverData = true
    const { data } = await verifyReciver(phoneNumber)
    this.verifyReceiverData = data
    this.isLoading.verifyReceiverData = false
  },

  async calculateGift(amount: number) {
    this.isLoading.calculatedGift = true
    try {
      const { data } = await calculateGift({
        amount,
        stock_symbol: this.stockData.symbol,
        phone_number: this.verifyReceiverData.phone_number
      })
      this.calculatedGift = data
    } catch (error) {
      this.hasError.calculatedGift = true
      return error
    } finally {
      this.isLoading.calculatedGift = false
    }
  },

  async createGift() {
    this.isLoading.paymentMethods = true
    try {
      const { data } = await createGift({
        amount: this.amount,
        symbol: this.stockData.symbol,
        phone_number: this.verifyReceiverData.phone_number,
        message: this.message
      })
      this.paymentMethods = data
    } catch (error) {
      this.hasError.paymentMethods = true
      return error
    } finally {
      this.isLoading.paymentMethods = false
    }
  },

  async depositMoney({ paymentMethodId, giftId }: { paymentMethodId: number; giftId: number }) {
    this.isLoading.paymentRedirectData = true
    try {
      const { data } = await depositMoney({
        payment_method_id: paymentMethodId,
        gift_id: giftId,
        amount: this.amount
      })
      this.paymentRedirectData = data
    } catch (error) {
      this.hasError.paymentRedirectData = true
      return error
    } finally {
      this.isLoading.paymentRedirectData = false
    }
  },

  async fetchDepositStatus({ txref, errorOccured }: { txref: string; errorOccured: any }) {
    this.isLoading.depositStatus = true
    try {
      const { data } = await depositStatus({
        txref
      })
      if (data.status !== 'Accepted') errorOccured()
      this.depositStatus = data
    } catch (error) {
      errorOccured()
      this.hasError.depositStatus = true
      return error
    } finally {
      this.isLoading.depositStatus = false
    }
  },

  toggleLoading(property: string) {
    ;(this.isLoading as any)[property] = (!this.isLoading as any)[property]
  },

  setStep(action: string) {
    switch (action) {
      case 'increment':
        this.step++
        break
      default:
        this.step = 0
        this.verifyReceiverData = { phone_number: '' }
        this.stockData = { symbol: '' }
        this.calculatedGift = { quantity: 0 }
        this.message = ''
        this.amount = 0
        this.paymentMethods = []
        this.paymentRedirectData = {}
        this.depositStatus = {}
    }
  },

  choosenStockData(stockData: any) {
    this.stockData = stockData
  },

  resetGiftCalculation() {
    this.calculatedGift = { quantity: 0 }
  },

  setMessage(message: string) {
    this.message = message
  },

  setAmount(amount: number) {
    this.amount = amount
  }
})

export default GiftingStore
