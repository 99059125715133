import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ControlContainer, HiddenSelectableAppearance, Label, ComponentProps } from '../mixins/SelectableAppearance'
import rgba from 'polished/lib/color/rgba'
import { Box } from 'components'

interface CheckboxProps {
  checked?: boolean
  small?: boolean
}

const HiddenToggle = styled.input.attrs({ type: 'checkbox' })`
  ${HiddenSelectableAppearance};
`

const StyledToggle = styled.label<CheckboxProps>`
  width: 2.75rem;
  height: 1.5rem;
  background-color: ${(props) => rgba(props.theme.colors.grey, 0.75)};
  transition: background-color 0.1s ease-out;
  box-shadow: 0 0.125rem 0.25rem ${rgba('#000', 0.04)};
  cursor: pointer;
  border-radius: 2.75rem;
  position: relative;
  &::after {
    content: '';
    height: 1.25rem;
    width: 1.25rem;
    background-color: ${(props) => props.theme.colors.white};
    transition: left 0.1s ease-out;
    border-radius: 50%;
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
  }
  ${(props) =>
    props.checked &&
    css`
      background-color: ${props.theme.colors.primary};
      &::after {
        left: calc(100% - 1.375rem);
      }
    `}
`

export default React.forwardRef((props: ComponentProps, ref?: any) => {
  const { id, children, checked, value, onChange, testId, ...rest } = props
  return (
    <ControlContainer>
      <HiddenToggle id={id} checked={checked} value={value} onChange={onChange} ref={ref} readOnly {...rest} />
      <StyledToggle htmlFor={id} checked={checked} onClick={onChange} data-testid={testId}>
        <Box position="absolute" top="-1px" left={checked ? '0.25rem' : '1.375rem'} fontSize="10px" color="white">
          {checked ? 'ON' : 'OFF'}
        </Box>
      </StyledToggle>
      {children && (
        <Label onClick={onChange} htmlFor={id}>
          {children}
        </Label>
      )}
    </ControlContainer>
  )
})
