import client from 'api/index'
import { Dictionary } from 'types'
import { USER_URLS } from 'constants/api'

export interface FullName {
  fullNameParams: Dictionary<string>
}

const call = ({ fullNameParams }: FullName) => {
  return client.patch(USER_URLS.profile.fullName, fullNameParams)
}

export default call
