import client from '../'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'

interface Login {
  loginParams: Dictionary<string>
}

const call = ({ loginParams }: Login) => {
  return client.post('login', snakeCaseKeys(loginParams))
}

export default call
