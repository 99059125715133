import client from 'api/index'
import { Dictionary } from 'types'
import { USER_URLS } from 'constants/api'
import snakeCaseKeys from 'snakecase-keys'

export interface PasswordProps {
  passwordParams: Dictionary<string>
}

const call = ({ passwordParams }: PasswordProps) => {
  return client.patch(USER_URLS.profile.password, snakeCaseKeys(passwordParams))
}

export default call
