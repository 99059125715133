import client from '../'
import snakeCaseKeys from 'snakecase-keys'
import { Dictionary } from 'types'

interface PermanentAddress {
  permanentAddressParams: Dictionary<string>
}

const call = ({ permanentAddressParams }: PermanentAddress) => {
  return client.post('permanent_address', snakeCaseKeys(permanentAddressParams))
}

export default call
