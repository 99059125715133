import styled from 'styled-components/macro'
import { boxSystem, BoxProps } from '../Box'

const Container = styled.div<BoxProps>`
  ${boxSystem};
  box-sizing: border-box;
`

Container.defaultProps = {
  maxWidth: '75.25rem',
  width: '100%',
  margin: '0 auto',
  padding: '0 1rem'
}

export default Container
