import { topMovers } from 'api/dashboard'
import { splitEvery } from 'ramda'

const TopMoversStore = () => ({
  topMovers: [],
  topMoversCurrencySymbol: '',
  isLoading: {
    topMovers: false
  },
  async fetchTopMovers() {
    this.isLoading.topMovers = true
    const { data } = await topMovers()
    this.topMovers = splitEvery(6, data.top_mover_stocks) as any
    this.topMoversCurrencySymbol = data.currency_symbol
    this.isLoading.topMovers = false
  }
})

export default TopMoversStore
