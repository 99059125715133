import React from 'react'
import { useTable, useRowSelect, useSortBy } from 'react-table'
import Table from '../Table'
import { Box, Spinner } from 'components'
import { InView } from 'react-intersection-observer'
import { ReactComponent as ArrowSortUp } from 'assets/icons/arrow-sort-up.svg'
import ExpandableTr from './ExpandableTr'

const DynamicTable = ({
  children,
  columns,
  data,
  rowPadding,
  testId,
  noInnerBorder = false,
  placeholder,
  loadMoreCallback,
  hasLoadMore,
  ...rest
}: any) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    useRowSelect
  )
  return (
    <Box overflowX="scroll">
      <Table {...getTableProps()} {...rest} disableResizing="true" position="relative">
        <Table.thead>
          {headerGroups.map((headerGroup: any, i: number) => (
            <Table.tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, i: number) => (
                <Table.th key={i} borderBottom={noInnerBorder ? 'none' : undefined}>
                  <Box display="flex" alignItems="center" height="1.5rem">
                    <Box
                      onClick={() =>
                        column.isSortedDesc ? column.toggleSortBy(false, false) : column.toggleSortBy(true, false)
                      }
                      style={{ cursor: column.sortable ? 'pointer' : 'auto' }}
                    >
                      {column.render('Header')}
                    </Box>
                    {column.sortable && (
                      <Box marginLeft="0.25rem" style={{ cursor: 'pointer' }}>
                        {column.isSortedDesc ? (
                          <Box as={ArrowSortUp} onClick={() => column.toggleSortBy(false, false)} height="0.75rem" />
                        ) : (
                          <Box
                            as={ArrowSortUp}
                            height="0.75rem"
                            style={{ transform: 'rotate(180deg)' }}
                            onClick={() => column.toggleSortBy(true, false)}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Table.th>
              ))}
            </Table.tr>
          ))}
        </Table.thead>

        {!rows.length && placeholder ? (
          <Box display={['none', 'block']} height="20rem">
            <Box position="absolute" top="1.75rem" width="100%" height="100%" textAlign="center">
              {children}
            </Box>
          </Box>
        ) : (
          <Table.tbody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => (
              <ExpandableTr
                row={row}
                prepareRow={prepareRow}
                key={i}
                noInnerBorder={noInnerBorder}
                rowPadding={rowPadding}
                testId={testId}
              />
            ))}
            {hasLoadMore && (
              <InView
                as={Box}
                onChange={(inView, entry) => {
                  if (entry.isIntersecting) {
                    loadMoreCallback()
                  }
                }}
              >
                <Box height="100%" display="flex" alignItems="center">
                  <Box display={['block', 'none']}>
                    <Spinner />
                  </Box>
                </Box>
              </InView>
            )}
          </Table.tbody>
        )}
      </Table>
    </Box>
  )
}

export default DynamicTable
