import React from 'react'
import styled from 'styled-components/macro'
import Calendar from 'react-calendar'
import Dropdown from '../Dropdown'
import Input from '../Input'
import '../../assets/styles/react-calendar.css'
import rgba from 'polished/lib/color/rgba'
import dayjs from 'dayjs'

const StyledCalendar = styled(Calendar)`
  border: none;
  font-family: ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.text};
  .react-calendar__tile--active {
    background-color: ${(props) => props.theme.colors.primary};
    &:enabled:hover,
    &:enabled:focus {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
  .react-calendar__tile--now {
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => rgba(props.theme.colors.primary, 0.1)};
  }
  .react-calendar__month-view__days__day {
    &:disabled {
      border-radius: 0;
    }
    &--weekend {
      color: ${(props) => props.theme.colors.text};
      font-weight: ${(props) => props.theme.fontWeights.semiBold};
    }
  }
  button:enabled:hover {
    background-color: ${(props) => rgba(props.theme.colors.lightGrey, 0.5)};
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }
  .react-calendar__month-view__days__day,
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow,
  .react-calendar__year-view__months__month {
    border-radius: ${(props) => props.theme.radii.default};
  }
  .react-calendar__navigation__arrow:disabled {
    border-radius: 0;
  }
`

const Datepicker = React.forwardRef(
  ({ placeholder, value, onChange, hasError, testId, height, ...rest }: any, ref?: any) => {
    const formatInputDate = (value: Date | string) => {
      if (!value) return
      return dayjs(value).format('YYYY-MM-DD')
    }

    return (
      <Dropdown
        Trigger={() => (
          <Input
            placeholder={placeholder}
            value={formatInputDate(value)}
            hasError={hasError}
            ref={ref}
            data-testid={testId}
            height={height}
            readOnly
          />
        )}
      >
        <StyledCalendar value={value} onChange={onChange} {...rest} />
      </Dropdown>
    )
  }
)

export default Datepicker
