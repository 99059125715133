import client from 'api'
import { GENERIC_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (verifyEmailParams: Dictionary<any>) => {
  return client.post(GENERIC_URLS.verifyEmail, snakeCaseKeys(verifyEmailParams))
}

export default call
