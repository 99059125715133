import client from 'api/index'
import { STOCKS_URLS } from 'constants/api'

interface WatchlistParams {
  themeId?: number | string | null | undefined
  filterParams?: string
}

const call = ({ themeId, filterParams }: WatchlistParams) => {
  return client.get(STOCKS_URLS.watchlist, {
    params: {
      theme_id: themeId,
      filters: filterParams
    }
  })
}

export default call
