import React from 'react'
import Box, { boxSystem } from '../Box'
import MaskedInput from 'react-text-mask'
import styled from 'styled-components/macro'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 9,
  allowNegative: false,
  allowLeadingZeroes: false
}

const StyledMonetaryInput = styled(Box)`
  ${boxSystem};
  &:focus {
    outline: none;
  }
`.withComponent(MaskedInput)

StyledMonetaryInput.defaultProps = {
  width: '100%',
  color: 'text',
  fontSize: '1.75rem',
  fontWeight: 'semiBold',
  fontFamily: 'body',
  textAlign: 'center',
  border: 'none',
  margin: 0,
  padding: '0.5rem 0',
  backgroundColor: 'transparent'
}

const MonetaryInput = React.forwardRef(({ maskOptions, ...rest }: any, ref: any) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions
  })

  return (
    <StyledMonetaryInput
      mask={currencyMask}
      onInput={(event: any) => {
        event.persist()
        return event
      }}
      ref={ref}
      {...rest}
    />
  )
})

export default MonetaryInput
