import React from 'react'
import styled from 'styled-components/macro'
import {
  ControlContainer,
  HiddenSelectableAppearance,
  SelectableAppearance,
  SelectableAppearanceActive,
  Label,
  ComponentProps
} from '../mixins/SelectableAppearance'

interface CheckboxProps {
  checked?: boolean
}

const StyledRadio = styled.label<CheckboxProps>`
  ${SelectableAppearance};
  border-radius: 50%;
`

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  ${HiddenSelectableAppearance};
  &:checked + ${StyledRadio} {
    ${SelectableAppearanceActive};
  }
`

export default React.forwardRef((props: ComponentProps, ref?: any) => {
  const { id, children, checked, value, onChange, ...rest } = props

  return (
    <ControlContainer>
      <HiddenRadio id={id} checked={checked} value={value} onChange={onChange} ref={ref} readOnly {...rest} />
      <StyledRadio htmlFor={id} checked={checked} onChange={onChange} />
      {children && (
        <Label onClick={onChange} htmlFor={id}>
          {children}
        </Label>
      )}
    </ControlContainer>
  )
})
