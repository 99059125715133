import { depositMethods, paymentMethods, deposit, status, instantAvailable } from 'api/deposit'

const DepositStore = () => ({
  instantAvailability: {},
  usdDepositMethods: [],
  paymentMethods: [],
  deposit: {
    amount: '',
    method: '',
    paymentMethodId: 0
  },
  depositState: {},
  isLoading: {
    usdMethods: false,
    paymentMethods: false,
    state: false
  },
  hasError: {
    usdMethods: false,
    instantAvailability: false
  },
  async fetchUsdDepositMethods() {
    this.isLoading.usdMethods = true
    const {
      data: { deposit_methods }
    } = await depositMethods()
    this.usdDepositMethods = deposit_methods
    this.isLoading.usdMethods = false
  },
  async fetchUsdPaymentMethods({ handleError, handleSuccess }: any) {
    this.isLoading.paymentMethods = true
    try {
      const {
        data: { payment_methods }
      } = await paymentMethods({
        amount: parseFloat(this.deposit.amount).toFixed(2),
        depositType: this.deposit.method
      })
      this.paymentMethods = payment_methods
      handleSuccess()
    } catch (e) {
      handleError(e.response?.data.message ? e.response?.data.message : e.response?.data)
    }
    this.isLoading.paymentMethods = false
  },
  async redirectToPaymentUrl() {
    const { data } = await deposit({
      paymentMethodId: this.deposit.paymentMethodId,
      depositType: this.deposit.method,
      amount: parseFloat(this.deposit.amount)
    })
    window.location = data.deposit_link
  },
  async fetchDepositState(txref: string) {
    this.isLoading.state = true
    const { data } = await status(txref)
    this.depositState = data
    this.isLoading.state = false
  },
  async instantDepositAvailability() {
    this.hasError.instantAvailability = false
    try {
      const { data } = await instantAvailable()
      this.instantAvailability = data
    } catch {
      this.hasError.instantAvailability = true
    }
  },
  setDepositAmount(amount: string) {
    this.deposit.amount = amount
  },
  setDepositMethod(method: string) {
    this.deposit.method = method
  },
  setDepositPaymentMethodId(id: number) {
    this.deposit.paymentMethodId = id
  }
})

export default DepositStore
