import client from 'api/index'

export interface StockSearch {
  query: string
  themeId?: number | null
  filterParams?: string
  handleError?: any
}

const call = ({ query, themeId, filterParams }: StockSearch) => {
  return client.get('stock/search', {
    params: {
      query,
      theme_id: themeId,
      filters: filterParams
    }
  })
}

export default call
