import client from '../'
import { DEPOSIT_URLS } from 'constants/api'
import { Dictionary } from 'types'
import snakeCaseKeys from 'snakecase-keys'

const call = (paymentMethods: Dictionary<string>) => {
  return client.get(DEPOSIT_URLS.paymentMethods, { params: snakeCaseKeys(paymentMethods) })
}

export default call
