import client from 'api'
import { DEPOSIT_URLS } from 'constants/api'
import { DepositParams } from 'types/deposit'
import snakeCaseKeys from 'snakecase-keys'

const call = (depositParams: DepositParams) => {
  return client.post(DEPOSIT_URLS.deposit, snakeCaseKeys(depositParams))
}

export default call
