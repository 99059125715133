import React, { useState, useRef } from 'react'
import styled from 'styled-components/macro'
import Box, { boxSystem } from '../Box'
import { Card } from 'components'
import Tippy from '@tippyjs/react'
import useClickOutside from '../../hooks/useClickOutside'

const StyledDropdown = styled(Box)`
  ${boxSystem};
  display: block;
  width: 100%;
  cursor: pointer;
`

const Dropdown = ({
  children,
  Trigger,
  hideOnInsideClick,
  placement = 'bottom',
  offset = [0, 16],
  minWidth = '10rem',
  contentPadding = '1rem',
  toggleCallback,
  ...rest
}: any) => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => {
    setIsVisible(!isVisible)
    toggleCallback && toggleCallback(true)
  }

  const contentRef = useRef(null)
  useClickOutside((event: any) => {
    const path = event.path || (event.composedPath && event.composedPath())
    const clickedTrigger = path[1]?.classList?.contains('dropdown-trigger')
    if (!clickedTrigger) {
      setIsVisible(false)
      toggleCallback && toggleCallback(false)
    }
  }, contentRef)

  const onInsideClick = () => {
    if (hideOnInsideClick) {
      setIsVisible(false)
      toggleCallback && toggleCallback(false)
    }
  }

  return (
    <StyledDropdown>
      <Tippy
        offset={offset}
        content={
          <Card onClick={onInsideClick} ref={contentRef} padding={contentPadding} minWidth={minWidth}>
            {children}
          </Card>
        }
        visible={isVisible}
        placement={placement}
        {...rest}
      >
        <span onClick={toggle}>
          <Trigger />
        </span>
      </Tippy>
    </StyledDropdown>
  )
}

export default Dropdown
