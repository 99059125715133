import React from 'react'
import { Box, ContentLoaderRect } from '../index'
import { formatCurrency } from 'utils/format'
import ContentLoader from 'react-content-loader'
import theme from 'theme'
import { useTranslation } from 'react-i18next'

const PortfolioValueFormatter = ({
  value,
  centered,
  markSymbol,
  currencySymbol = '$',
  isLoading = false,
  color = 'greyText'
}: {
  value: number
  centered?: boolean
  markSymbol?: boolean
  currencySymbol?: string
  isLoading?: boolean
  largeFontSize?: string
  smallFontSize?: string
  color?: string
}) => {
  const [wholeUnit, fractionalUnit] = formatCurrency({ value })
    .substring(value < 0 ? 2 : 1)
    .split('.')
  const { t } = useTranslation()

  return isLoading ? (
    <ContentLoader width="100%" height="2.75rem">
      <ContentLoaderRect />
    </ContentLoader>
  ) : (
    <Box display="flex" alignItems="center" justifyContent={(centered && 'center') || ''}>
      <Box
        display={(markSymbol && value !== 0 && 'flex') || 'none'}
        backgroundColor={theme.colors.white}
        fontSize="0.75rem"
        height="1rem"
        fontWeight="semiBold"
        borderRadius="2px"
        justifyContent="center"
        alignItems="center"
        marginRight="0.25rem"
        padding="0 0.25rem"
        color={value < 0 ? theme.colors.red : theme.colors.primary}
      >
        {value < 0 ? t('Common:Down') : t('Common:Up')}
      </Box>
      <Box display="flex" alignItems="flex-end">
        <Box
          fontSize={['1.5rem', '1rem', '1.25rem', '1.5rem']}
          lineHeight={['1.5rem', '1rem', '1.25rem', '1.5rem']}
          marginBottom="2px"
          color={color}
        >
          {currencySymbol}
        </Box>
        <Box
          fontSize={['1.75rem', '1.25rem', '1.5rem', '1.75rem']}
          lineHeight={['1.75rem', '1.25rem', '1.5rem', '1.75rem']}
          fontWeight="semiBold"
          color={color}
        >
          {wholeUnit}
        </Box>
        <Box
          fontSize={['1.5rem', '1rem', '1.25rem', '1.5rem']}
          lineHeight={['1.5rem', '1rem', '1.25rem', '1.5rem']}
          color={color}
        >
          .{fractionalUnit}
        </Box>
      </Box>
    </Box>
  )
}

export default PortfolioValueFormatter
