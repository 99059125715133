import styled, { css } from 'styled-components/macro'
import { variant } from 'styled-system'

interface InputAppearanceProps {
  hasError?: boolean
  readOnly?: boolean
}

const InputAppearance = css<InputAppearanceProps>`
  ${({ theme }) => css`
    appearance: none;
    display: flex;
    width: 100%;
    padding: 0.675rem 1rem;
    font-size: 1rem;
    box-sizing: border-box;
    transition: border 0.25s ease-out, box-shadow 0.25s ease-out;
    border: ${theme.borders.default};
    border-radius: ${theme.radii.default};
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.body};
    &:disabled {
      background-color: ${theme.colors.white};
      cursor: not-allowed;
    }
    &:focus {
      outline: none;
      border: ${theme.borders.primary};
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    }
  `}
  ${(props) =>
    props.hasError &&
    css`
      border: ${props.theme.borders.error};
    `}
    ${(props) =>
    props.readOnly &&
    css`
      &:disabled {
        background-color: ${props.theme.colors.white};
      }
    `}
  ${variant({
    prop: 'size',
    variants: {
      small: {
        padding: '0.625rem 0.75rem'
      }
    }
  })}
`

interface IconProps {
  position?: string
}

export const Icon = styled.div<IconProps>`
  position: absolute;
  top: calc(50% - 0.75rem);
  text-align: center;
  width: 1.5rem;
  height: 100%;
  ${(props) =>
    props.position === 'right'
      ? `
    right: 0.75rem;
  `
      : `
    left: 0.5rem;
  `}
`

export default InputAppearance
