import Box, { boxSystem } from '../Box'
import styled from 'styled-components/macro'
import React from 'react'
import InputAppearance from '../mixins/InputAppearance'

const StyledTextArea = styled(Box)`
  ${boxSystem};
  ${InputAppearance};
  margin: 4rem 0 1rem;
  border-bottom: 1px ${(props) => props.theme.colors.primary} solid;
  outline-bottom: 1px solid red;
  resize: none;
  &:focus {
    caret-color: ${(props) => props.theme.colors.primary};
  }
`.withComponent('textarea')

export default React.forwardRef((props: any, ref?: any) => {
  const { placeholder, id, ...rest } = props
  return (
    <Box position="relative">
      <StyledTextArea id={id} placeholder={placeholder} ref={ref} {...rest} />
    </Box>
  )
})
