import { calculate, order, cancel } from 'api/orders'
import { CalculationParams, OrderCalculationParams } from 'types/orders'
import { ORDER_TYPES, ORDER_SIDES } from 'constants/order'

const OrderStore = () => ({
  order: {
    side: ORDER_SIDES.BUY,
    type: '',
    symbol: '',
    amount: '',
    limit: 0,
    step: 0,
    quantity: 0,
    price: 0
  },
  calculation: {
    quantity: 0,
    orderPrice: 0,
    totalPrice: 0,
    fee: 0,
    currencySymbol: ''
  },
  isLoading: {
    calculation: false,
    order: false
  },
  hasError: {
    calculation: false,
    order: false
  },
  setCalculation(calculation: OrderCalculationParams) {
    this.calculation.quantity = calculation.quantity
    this.calculation.orderPrice = calculation.order_price
    this.calculation.totalPrice = calculation.total_price
    this.calculation.fee = calculation.fee
    this.calculation.currencySymbol = calculation.currency_symbol
  },
  setOrderData(orderData: CalculationParams) {
    this.order.amount = orderData.amount
    this.order.type = orderData.type
    this.order.side = orderData.side
    this.order.symbol = orderData.symbol
    this.order.price = orderData.price
  },
  setStep(step: number) {
    this.order.step = step
  },
  setSide(orderSide: string) {
    this.order.side = orderSide
  },
  setOrderStockSymbol(symbol: string) {
    this.order.symbol = symbol
  },
  setLimit(limit: number) {
    this.order.limit = limit
  },
  setQuantity(quantity: number) {
    this.order.quantity = quantity
  },
  resetCalculation() {
    this.calculation.quantity = 0
  },
  async fetchCalculation(calculationParams: CalculationParams) {
    this.isLoading.calculation = true
    const { data } = await calculate(calculationParams)
    this.setCalculation(data)
    this.isLoading.calculation = false
  },
  async submitOrder({ errorOccurred }: { errorOccurred: any }) {
    this.isLoading.order = true
    try {
      await order({
        symbol: this.order.symbol,
        side: this.order.side,
        totalPrice: this.calculation.totalPrice,
        orderType: this.order.type,
        quantity: this.calculation.quantity,
        fee: this.calculation.fee,
        price: this.order.type != ORDER_TYPES.MARKET ? this.order.price : undefined
      })
      this.setStep(3)
    } catch {
      this.setStep(1)
      errorOccurred && errorOccurred()
    }
    this.isLoading.order = false
  },
  getLimitAmount(quantity: number) {
    return this.order.limit * quantity
  },
  cancelOrder(id: number) {
    return cancel(id)
  }
})

export default OrderStore
