import client from 'api'

interface depositMoneyCall {
  payment_method_id: number
  gift_id: number
  amount: number
}

const call = ({ payment_method_id, gift_id, amount }: depositMoneyCall) => {
  return client.post('/gifting/deposit', {
    payment_method_id,
    gift_id,
    amount
  })
}

export default call
