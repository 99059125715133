import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import 'mobx-react-lite/batchingForReactDom'
import App from 'App'
import * as serviceWorker from 'serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { Normalize } from 'styled-normalize'
import GlobalStyle from 'utils/GlobalStyle'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  beforeSend(event): any {
    if (process.env.REACT_APP_SENTRY_ENV === 'development') return null
    return event
  }
})

ReactDOM.render(
  <React.StrictMode>
    <Normalize />
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.register()
reportWebVitals()
