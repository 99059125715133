import { themes, themeStocks } from 'api/generic'
import { navigate } from '@reach/router'
import { splitEvery } from 'ramda'

const ThemesStore = () => ({
  featuredThemes: [],
  featuredTheme: {},
  stocks: [],
  isLoading: {
    stocks: false,
    featuredThemes: false
  },
  async fetchFeaturedThemes() {
    this.isLoading.featuredThemes = true
    const { data } = await themes()
    this.featuredThemes = data.featured_themes
    this.isLoading.featuredThemes = false
  },
  async fetchFeaturedTheme(themeId: string) {
    await this.fetchFeaturedThemes()
    try {
      this.featuredTheme = this.getTheme(parseInt(themeId))
      await this.fetchFeaturedThemeStocks(themeId)
    } catch {
      navigate('/dashboard')
    }
  },
  async fetchFeaturedThemeStocks(themeId: string) {
    this.isLoading.stocks = true
    this.stocks = []
    const { data } = await themeStocks(themeId)
    this.stocks = data.featured_theme_stocks
    this.isLoading.stocks = false
  },
  getTheme(id: number) {
    return this.featuredThemes?.filter((theme: any) => theme.id === id)?.[0]
  },
  get featuredThemesSplittedList() {
    return splitEvery(6, this.featuredThemes) as any
  }
})

export default ThemesStore
