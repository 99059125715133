import { createGlobalStyle } from 'styled-components/macro'
import theme from 'theme'
import 'keen-slider/keen-slider.min.css'

const GlobalStyle = createGlobalStyle`
  *::-webkit-scrollbar {
    display: none;
  }
  html,
  body {
    min-height: 100vh;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
  }
  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    cursor: pointer;
  }
  .react-toast-notifications__toast__content {
    font-family: ${theme.fonts.body};
  }
  * {
    min-height: 0;
    min-width: 0;
    letter-spacing: 0;
  }
`

export default GlobalStyle
