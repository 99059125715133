import styled from 'styled-components/macro'
import Card from '../Card'
import Box, { boxSystem } from '../Box'
import theme from 'theme'

const Table: any = styled(Card).attrs({ cellPadding: 0, cellSpacing: 0 })`
  ${boxSystem};
`.withComponent('table')

Table.defaultProps = {
  width: '100%',
  padding: 0,
  borderSpacing: 0,
  borderCollapse: 'collapse'
}

const Thead = styled(Box)`
  ${boxSystem};
`.withComponent('thead')

Thead.defaultProps = {
  display: ['none', 'table-header-group'],
  flex: 1,
  width: '100%',
  letterSpacing: '0.05rem',
  color: 'grey'
}

const Tbody = styled(Box)`
  ${boxSystem};
`.withComponent('tbody')

Tbody.defaultProps = {
  width: '100%',
  flex: 1,
  color: 'text'
}

const Td = styled(Box)`
  ${boxSystem};
  position: relative;
  &:first-of-type {
    padding-left: 1.5rem !important;
  }
  &:last-of-type {
    padding-right: 1.5rem !important;
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.lighterGrey};
  }
  &:first-of-type:after {
    margin-left: 1.5rem;
  }
  &:last-of-type:after {
    width: calc(100% - 1.5rem);
  }
  @media screen and (max-width: 639px) {
    border-bottom: ${theme.borders.default};
    border-color: ${theme.colors.lighterGrey};
    &:after {
      display: none;
    }
    &:before {
      text-align: left;
      text-transform: uppercase;
      font-size: 0.75rem;
    }
    &:not([data-label='false']):before {
      content: attr(data-label);
    }
  }
`.withComponent('td')

Td.defaultProps = {
  display: ['flex', 'table-cell'],
  justifyContent: 'space-between',
  textAlign: ['right', 'left'],
  alignItems: 'center',
  height: '100%',
  flex: 1,
  padding: '1rem'
}

const Tr = styled(Box)`
  ${boxSystem};
  &:last-of-type > td:after {
    display: none;
  }
  @media screen and (max-width: 639px) {
    &:last-of-type > td {
      border-bottom: ${theme.borders.default};
    }
    &:first-of-type {
      border-top: none;
    }
    border-bottom: none;
  }
`.withComponent('tr')

Tr.defaultProps = {
  display: ['block', 'table-row'],
  width: '100%',
  minWidth: '14rem',
  flexDirection: 'column',
  borderTop: ['default', 'none']
}

const Th = styled(Box)`
  ${boxSystem};
  text-transform: uppercase;
`.withComponent('th')

Th.defaultProps = {
  alignItems: 'center',
  height: '100%',
  flex: 1,
  padding: '1rem',
  fontSize: '0.75rem',
  textAlign: 'left',
  fontWeight: 'normal',
  borderBottom: 'default',
  borderColor: 'lighterGrey'
}

Table.thead = Thead
Table.tbody = Tbody
Table.td = Td
Table.tr = Tr
Table.th = Th

export default Table
