import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'
import rgba from 'polished/lib/color/rgba'
import Box from '../Box'
import { fadeIn, slideDown } from '../mixins/animations'
import { ReactComponent as XIcon } from 'assets/icons/x.svg'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import { useHotkeys } from 'react-hotkeys-hook'

const DrawerOverlay = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  overflow-y: auto;
  max-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: ${(props) => rgba(props.theme.colors.navy, 0.85)};
  cursor: pointer;
  z-index: 2;
  animation: ${fadeIn} 0.15s ease-out;
`

DrawerOverlay.defaultProps = {
  alignItems: ['flex-start', 'center']
}

const DrawerContainer = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 2;
  border-top-left-radius: ${(props) => props.theme.radii.default};
  border-bottom-left-radius: ${(props) => props.theme.radii.default};
  box-shadow: 0 0.5rem 1rem ${(props) => rgba(props.theme.colors.darkBackground, 0.075)};
  cursor: default;
  animation: ${slideDown} 0.15s ease-out;
`

DrawerContainer.defaultProps = {
  padding: ['2rem 4rem', '3.5rem 8.25rem 3.5rem 8rem'],
  margin: 'auto 0'
}

const Footer = styled(Box)`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
`

export const stopPropagation = (e: any) => {
  e.stopPropagation()
}

interface ModalProps {
  isVisible: boolean
  hasX?: boolean
  onHide: any
  children?: any
  as?: any
  onSubmit?: any
  noValidate?: boolean
  testId?: string
  maxWidth?: string
}

const Drawer = ({
  as,
  isVisible,
  hasX,
  onHide,
  children,
  onSubmit,
  noValidate,
  testId,
  maxWidth,
  ...rest
}: ModalProps & any) => {
  useHotkeys('escape', () => isVisible && onHide())

  return isVisible
    ? ReactDOM.createPortal(
        <DrawerOverlay onClick={onHide}>
          <ScrollLock isActive={isVisible} />
          <TouchScrollable>
            <DrawerContainer
              as={as}
              onSubmit={onSubmit}
              onClick={stopPropagation}
              data-testid={testId}
              noValidate={noValidate}
              maxWidth={maxWidth ? maxWidth : '40rem'}
              {...rest}
            >
              {hasX && (
                <Box
                  position="absolute"
                  color="primary"
                  top={['1rem', '1.5rem']}
                  left={['1rem', '1.5rem']}
                  onClick={onHide}
                  style={{ cursor: 'pointer' }}
                >
                  <Box as={XIcon} width="1.5rem" height="1.5rem" />
                </Box>
              )}
              <Box marginTop={['1rem', 0]} width="100%">
                {children}
              </Box>
            </DrawerContainer>
          </TouchScrollable>
        </DrawerOverlay>,
        document.body
      )
    : null
}

Drawer.Footer = Footer

export default Drawer
