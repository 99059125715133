import store from 'store2'
import { Dictionary } from 'types'
import dayjs from 'dayjs'
import {
  verifyPhoneNumber,
  verifyBvn,
  signAgreements,
  permanentAddress,
  login,
  profile,
  nextOfKin,
  nextOfKinRelationships,
  updateNextOfKin,
  createNextOfKin,
  externalLinks
} from 'api/user'
import { getSettings, updateSettings } from 'api/notifications'
import { PermanentAddress, DigitalSignature, Login } from 'types/signup'
import { setIntercomData } from 'utils/intercom'
import { Profile } from 'api/user/profile/patch'
import { FullName } from 'api/user/profile/fullName'
import { PasswordProps } from 'api/user/profile/password'
export const persist = store.namespace('UserStore')
export const getPersisted = (property: string, fallback: string | number | null = '') =>
  persist.get(property) || fallback
export const logout = () => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  Intercom('shutdown')
  localStorage.clear()
  return window.location.reload()
}

const UserStore = () => ({
  jwt: getPersisted('jwt', ''),
  refreshToken: getPersisted('refresh-token', ''),
  email: '',
  emailVerified: false,
  fullName: '',
  name: '',
  surname: '',
  phoneNumber: '',
  registrationStep: '',
  accountNumber: '',
  userId: getPersisted('user-id', ''),
  address: {
    street: '',
    city: '',
    postalCode: '',
    province: '',
    citizenship: ''
  },
  nextOfKinName: '',
  nextOfKin: {},
  wasNextOfKinFilled: false,
  nextOfKinRelationships: [],
  referralSourceSet: false,
  notificationsSettings: {},
  agreementsLinks: [],
  accountRestriction: {},
  isLoading: {
    bvn: false,
    verification: false,
    agreements: false,
    permanentAddress: false,
    login: false,
    profile: false,
    nextOfKin: false,
    nextOfKinRelationships: false,
    notificationsSettings: false
  },
  hasError: {
    updatePassword: false,
    nextOfKin: false
  },
  async verifyPhoneNumber({ verificationCode, phoneNumber, registrationStep }: any) {
    this.isLoading.verification = true
    const { data } = (await verifyPhoneNumber({
      phoneVerificationParams: {
        verificationCode,
        phoneNumber
      }
    })) as any
    persist.set('user-id', data.user.id)
    this.registrationStep = registrationStep
    this.setJwt(data.jwt)
    this.isLoading.verification = false
  },
  async verifyBvn({ bvn }: any) {
    this.isLoading.bvn = true
    await verifyBvn({
      bvnParams: {
        bvn
      }
    })
    this.isLoading.bvn = false
  },
  async signAgreements({ digitalSignature }: DigitalSignature) {
    this.isLoading.agreements = true
    await signAgreements({
      agreementsParams: {
        fullName: digitalSignature
      }
    })
    this.isLoading.agreements = false
  },
  async permanentAddress({ address, city, state, country }: PermanentAddress) {
    this.isLoading.permanentAddress = true
    await permanentAddress({
      permanentAddressParams: {
        address,
        city,
        state,
        country
      }
    })
    this.isLoading.permanentAddress = false
  },
  async login({ phonePrefix, phoneNumber, password }: Login) {
    this.isLoading.login = true
    const fullNumber = `${phonePrefix}${phoneNumber}`
    const { data } = await login({
      loginParams: {
        phoneNumber: fullNumber,
        password
      }
    })
    persist.set('user-id', data.user.id)
    persist.set('refresh-token', data.refresh_token)
    this.setJwt(data.jwt)
    this.isLoading.login = false
  },
  async fetchProfile() {
    this.isLoading.profile = true
    const { data } = await profile.get()
    setIntercomData(data)
    this.name = data.name
    this.surname = data.surname
    this.fullName = `${data.name} ${data.surname}`
    this.email = data.email
    this.emailVerified = data.email_verified
    this.phoneNumber = data.phone_number
    this.accountNumber = data.account_number
    this.nextOfKinName = data.next_of_kin
    this.referralSourceSet = data.referral_source_set
    this.address.street = data.street
    this.address.province = data.province
    this.address.city = data.city
    this.address.citizenship = data.citizenship
    this.address.postalCode = data.postal_code
    this.accountRestriction = data.account_restriction
    this.isLoading.profile = false
  },
  updateFullName(payload: FullName) {
    return profile.fullName(payload)
  },
  updateEmail(payload: Profile) {
    return profile.patch(payload)
  },
  updatePassword(payload: PasswordProps) {
    return profile.password(payload)
  },
  resendVerificationEmail() {
    return profile.resendVerificationEmail()
  },
  logout,
  setJwt(jwt: string) {
    return persist.set('jwt', jwt)
  },
  toggleLoading(property: string) {
    ;(this.isLoading as any)[property] = (!this.isLoading as any)[property]
  },
  getValidRegistrationStep() {
    return this.registrationStep === 'BVN' ? '/signup/bvn' : '/signup/address'
  },
  getFullAddress() {
    return `${this.address.street}, ${this.address.postalCode} ${this.address.city}`
  },
  async fetchNextOfKin() {
    this.isLoading.nextOfKin = true
    this.hasError.nextOfKin = false
    this.nextOfKin = {}
    try {
      const { data } = await nextOfKin()
      this.nextOfKin = data
      this.wasNextOfKinFilled = true
    } catch (e: any) {
      if (e.response.status === 404) {
        this.wasNextOfKinFilled = false
      } else {
        this.hasError.nextOfKin = true
      }
    } finally {
      this.isLoading.nextOfKin = false
    }
  },
  async fetchNextOfKinRelationships() {
    this.isLoading.nextOfKinRelationships = true
    this.nextOfKinRelationships = []
    const {
      data: { relationships }
    } = await nextOfKinRelationships()
    this.nextOfKinRelationships = relationships
    this.isLoading.nextOfKinRelationships = false
  },
  async updateNextOfKin(nextOfKinProps: Dictionary<string>) {
    if (this.wasNextOfKinFilled) {
      const { data } = await updateNextOfKin(this.getSanitizedKinData(nextOfKinProps))
      this.nextOfKin = data
    } else {
      const { data } = await createNextOfKin(this.getSanitizedKinData(nextOfKinProps))
      this.nextOfKin = data
    }
  },
  getSanitizedKinData(data: Dictionary<string>) {
    let preparedData: Dictionary<string>
    if (data.fullPhoneNumber) {
      const { fullPhoneNumber, ...rest } = data
      preparedData = { phoneNumber: fullPhoneNumber, ...rest }
    } else {
      const { PhonePrefix, PhoneNumber, ...rest } = data
      preparedData = { phoneNumber: `${PhonePrefix}${PhoneNumber}`, ...rest }
    }
    preparedData.dateOfBirth = dayjs(preparedData.dateOfBirth).format('YYYY-MM-DD')
    return preparedData
  },
  async fetchNotificationsSettings() {
    this.isLoading.notificationsSettings = true
    try {
      const { data } = await getSettings()
      this.notificationsSettings = data
    } catch (error: any) {
      if (error.response.status !== 404) throw error
    }
    this.isLoading.notificationsSettings = false
  },
  updateNotificationsSettings(notificationsSettings: Dictionary<boolean | number>) {
    return updateSettings(notificationsSettings)
  },
  async fetchAgreements() {
    const { data } = await externalLinks()
    this.agreementsLinks = data
  }
})

export default UserStore
