import client from 'api'

interface calculateGiftCall {
  amount: number
  stock_symbol: string
  phone_number: string
}

const call = ({ amount, stock_symbol, phone_number }: calculateGiftCall) => {
  return client.post('/gifting/calculate', {
    amount,
    stock_symbol,
    phone_number
  })
}

export default call
