import { useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { useStore } from 'store'

const PaymentRedirectView = (props: RouteComponentProps) => {
  const { GenericStore } = useStore()

  const locationReplace = (url: string) => {
    return window.location.replace(url)
  }

  const redirectToMobile = (path: string) => {
    return locationReplace(`bamboo://${path}`)
  }

  const isMobile = () => !!navigator.userAgent.match(/Android|iPhone|iPad|iPod/i)

  useEffect(() => {
    const params = new URLSearchParams(props.location?.search)
    const txref = params.get('txref')
    const isCurrencyDeposit = !!params.get('user_currency_deposit')
    const isGifting = !!params.get('gifting')
    /* Mobile redirects */
    if (isMobile()) {
      if (txref) {
        switch (true) {
          case isGifting:
            return redirectToMobile(`gifting-payment-status.com?txref=${txref}`)
          case isCurrencyDeposit:
            return redirectToMobile(`user-currency-payment-status.com?txref=${txref}`)
          default:
            return redirectToMobile(`payment-status.com?txref=${txref}`)
        }
      }
      return redirectToMobile(`payment-status.com?txref=${txref}`)
    }
    /* Desktop redirects */
    if (txref) {
      GenericStore.setTxref(txref)
      switch (true) {
        case isGifting:
          navigate('dashboard/gifting/success', {
            state: { txref }
          })
          return
        case isCurrencyDeposit:
          /* User Currency Wallet Deposit */
          GenericStore.setIsCurrencyDeposit(true)
          navigate('/dashboard')
          return
        default:
          /* USD Wallet Deposit */
          navigate('/dashboard')
          return
      }
    }
    if (isGifting) {
      return console.warn('GIFTING NO TXREF')
    }
    return console.warn('DEPOSIT NO TXREF')
  })
  return null
}

export default PaymentRedirectView
