import client from 'api/index'
import { FIXED_RETURNS_URL } from 'constants/api'
import snakeCaseKeys from 'snakecase-keys'
import { Invest } from 'types/fixedReturns'

const call = (fixedReturnsParam: Invest) => {
  return client.post(FIXED_RETURNS_URL.fixedReturns, snakeCaseKeys(fixedReturnsParam))
}

export default call
