import React from 'react'
import { Box, Spinner } from 'components'

const LoadingOverlay = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" minWidth="100vh">
      <Spinner size="3rem" color="primary" />
    </Box>
  )
}

export default LoadingOverlay
