import {
  exchangeRate,
  dictionary,
  registrationStep,
  referralSource,
  referralSourceOptions,
  verifyEmail,
  monthChoice,
  acceptGift,
  featureFlags
} from 'api/generic'
import { Dictionary } from 'types'
import { CASH_ACTIONS } from 'constants/depositsAndWithdrawals'
import { helper, isIdValid } from 'vridgen'
import { persist } from 'store/UserStore'

const GenericStore = () => ({
  exchangeRate: 0,
  txref: '',
  isCurrencyDeposit: false,
  loginRedirectionPath: '',
  dictionary: {} as any,
  registrationStep: '',
  fundedAccount: false,
  referralSourceOptions: [],
  monthChoice: {},
  cashAction: CASH_ACTIONS.DEPOSIT,
  cashActionStep: 0,
  featureFlags: {},
  partnerRefrence: {
    UID: '',
    verified: false,
    ref: ''
  },
  isLoading: {
    exchangeRate: false,
    dictionary: false,
    registrationStep: false,
    monthChoice: false
  },
  hasError: {
    exchangeRate: false
  },
  async fetchExchangeRate(currency = 'NGN') {
    this.isLoading.exchangeRate = true
    const {
      data: { exchange_rate }
    } = await exchangeRate(currency)
    this.exchangeRate = exchange_rate
    this.isLoading.exchangeRate = false
  },
  async fetchDictionary() {
    this.isLoading.dictionary = true
    const { data } = await dictionary()
    this.dictionary = data
    this.isLoading.dictionary = false
  },
  async fetchRegistrationStep() {
    this.isLoading.registrationStep = true
    const { data } = await registrationStep()
    this.registrationStep = data.registration_step
    this.fundedAccount = data.funded_account
    this.isLoading.registrationStep = false
  },
  async fetchReferralSourceOptions() {
    const {
      data: { options }
    } = await referralSourceOptions()
    this.referralSourceOptions = options
  },
  async verifyEmail({ hash }: { hash: string }) {
    await verifyEmail({ verificationHash: hash })
  },
  async fetchMonthChoice() {
    this.isLoading.monthChoice = true
    const { data } = await monthChoice()
    this.monthChoice = data
    this.isLoading.monthChoice = false
  },
  async fetchFeatureFlags() {
    const { data } = await featureFlags()
    this.featureFlags = data
  },
  acceptGift(giftIdentifier: string) {
    return acceptGift({ giftIdentifier })
  },
  setReferralSource(referralSourceParams: Dictionary<string>) {
    return referralSource(referralSourceParams)
  },
  setTxref(txref: string) {
    this.txref = txref
  },
  setIsCurrencyDeposit(isCurrencyDeposit: boolean) {
    this.isCurrencyDeposit = isCurrencyDeposit
  },
  setLoginRedirectionPath(loginRedirectionPath: string) {
    this.loginRedirectionPath = loginRedirectionPath
  },
  getExchangeApproximation({ value, shouldMultiply = true }: any) {
    return shouldMultiply ? value * this.exchangeRate : value / this.exchangeRate
  },
  getIterable(key: string) {
    return this.dictionary[key] && Object.entries(this.dictionary[key])
  },
  setCashAction(action: string) {
    this.cashAction = action
  },
  setCashActionStep(step: number) {
    this.cashActionStep = step
  },
  setPartnerRefrence(UID: string, ref: string) {
    this.partnerRefrence = {
      UID,
      verified: isIdValid(UID).valid,
      ref
    }
    if (this.partnerRefrence.verified) {
      persist.setAll({ pruid: UID, ref }, true)
    }
  }
})

export default GenericStore
